import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import ConfirmationModal from '../../../../../ui/ConfirmationModal';
import { PencilIcon, TrashIcon } from '../../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { Permission } from '../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../utils/hooks/PermissionGate';
import { PartnerUser } from '../../../../../utils/types/admin_types';
import rowStyles from '../../../access_management/row.module.css';

type PartnerEmployeeRowProps = {
  partner: PartnerUser;
  deleteUser: (id: string) => void;
  onEditClick: (partner: PartnerUser) => void;
};

export const PartnerEmployeeRow = ({
  partner,
  deleteUser,
  onEditClick,
}: PartnerEmployeeRowProps) => {
  const [showModal, setShowModal] = useState(false);
  const groupListString = useMemo(() => {
    return partner.groups.map((group) => group.groupName).join(', ');
  }, [partner.groups]);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const confirmDelete = () => {
    deleteUser(partner.id);
    setShowModal(false);
  };

  return (
    <>
      <tr className={rowStyles.tableRowStyle}>
        <td>
          <input type='checkbox' />
        </td>
        <td className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}>
          <SecondaryTypography.XSmall>
            { partner.user.firstName + ' ' + partner.user.lastName }
          </SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>{ partner.user.email }</SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>{ groupListString || 'Empty' }</SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>
            { partner.lastActive ? new Date(partner.lastActive * 1000).toString() : 'Never Logged-In' }
          </SecondaryTypography.XSmall>
        </td>
        <td className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}>
          <div className={rowStyles.iconContainer}>
            <PermissionGate requiredPermissions={[Permission.PARTNER_EDIT]}>
              <div
                  className={classNames(rowStyles.icons, rowStyles.editIcon, rowStyles.clickable)}
                  onClick={() => onEditClick(partner)}
              >
                <PencilIcon />
              </div>
            </PermissionGate>
            <PermissionGate requiredPermissions={[Permission.PARTNER_DELETE]}>
              <div
                  className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                  onClick={handleDeleteClick}
              >
                <TrashIcon />
              </div>
            </PermissionGate>
          </div>
        </td>
      </tr>
      { showModal && (
        <ConfirmationModal
            message='Are you sure you want to delete this partner?'
            onConfirm={confirmDelete}
            onCancel={() => setShowModal(false)}
        />
      ) }
    </>
  );
};

export default PartnerEmployeeRow;
