import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../../ui/button/button';
import AsyncCustomSelect from '../../../../../../ui/custom_react_select/async_select';
import { CustomSelect } from '../../../../../../ui/custom_react_select/custom_select';
import { DatePicker } from '../../../../../../ui/input/date_picker/date_picker';
import { TextInput } from '../../../../../../ui/input/text_input/text_input';
import { ToggleInput } from '../../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import useDebouncedJobTitleSearch from '../../../../../../utils/helpers/AsyncSelect/JobTitleGet';
import { JobTitle } from '../../../../../../utils/types/admin_types';
import { JobDescription, JobPostingTabData, workArrangement } from '../../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';

type JobPostSettingsProps = {
    jobTitleList: JobTitle[],
    jobPostingData: JobPostingTabData,
    setJobPostingData: React.Dispatch<React.SetStateAction<JobPostingTabData>>,
    setActiveTab: (value: (((prevState: number) => number) | number)) => void,
    jobDescription: JobDescription,
}

const JobPostSettings = ({
  jobPostingData,
  setJobPostingData,
  jobTitleList,
  setActiveTab,
  jobDescription,
}: JobPostSettingsProps) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [initialData, setInitialData] = useState<JobPostingTabData>(jobPostingData);

  const workTypes: SelectOptions[] = [
    { label: 'Hybrid', value: 'Hybrid' },
    { label: 'Remote', value: 'Remote' },
    { label: 'Office', value: 'Office' },
  ];

  const { loadOptionsDebounced } = useDebouncedJobTitleSearch();

  const changeDate = (timeRange: any) => {
    setJobPostingData({
      ...jobPostingData,
      startDate: timeRange.startDate,
      endDate: timeRange.endDate,
    });
  };

  const defaultJobTitles = jobTitleList.map((jobTitle) => ({
    value: jobTitle.id,
    label: jobTitle.jobTitle,
  }));

  const handleJobTitleChange = (selectedOptions: SelectOptions) => {
    setJobPostingData({ ...jobPostingData, jobTitle: selectedOptions.label });
  };

  const handleChange = (selectedOption: SelectOptions) => {
    const workType: workArrangement = selectedOption.value as workArrangement;
    setJobPostingData({ ...jobPostingData, workType: workType });
  };

  const handleToggle = () => {
    setJobPostingData({ ...jobPostingData, active: !jobPostingData.active });
  };

  const handleSave = () => {
    setInitialData(jobPostingData);
    setIsSaveButtonDisabled(true);
    setActiveTab(1);
  };

  useEffect(() => {
    const isChanged = JSON.stringify(jobPostingData) !== JSON.stringify(initialData);
    const allFieldsSet =
            !!jobPostingData.location &&
            !!jobPostingData.startDate &&
            !!jobPostingData.endDate &&
            !!jobPostingData.jobTitle &&
            !!jobPostingData.workType &&
            typeof jobPostingData.active === 'boolean';

    setIsSaveButtonDisabled(!(isChanged && allFieldsSet));
  }, [jobPostingData, initialData]);

  return (
    <div className='relative flex flex-col gap-4 h-full p-10'>
      <DatePicker
          label='Time-Range'
          startDate={jobPostingData.startDate || new Date().toISOString().split('T')[0]}
          endDate={jobPostingData.endDate || new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0]}
          onChange={changeDate}
      />
      <div className='w-1/3'>
        <div className='text-gray-500 py-1 text-xs font-medium mt-4'>Location</div>
        <TextInput
            name='jobLocation'
            value={jobPostingData.location as string}
            type='text'
            onChange={(e) => setJobPostingData({ ...jobPostingData, location: e.target.value })}
        />
        <div className='text-gray-500 py-1 text-xs font-medium mt-4'>Department</div>
        <TextInput
            name='jobDepartment'
            value={jobPostingData.jobDepartment as string}
            type='text'
            onChange={(e) => setJobPostingData({ ...jobPostingData, jobDepartment: e.target.value })}
        />
        <div className='text-gray-500 py-1 text-xs font-medium mt-4'>Job Title</div>
        <AsyncCustomSelect
            defaultOptions={defaultJobTitles}
            value={{
              value: jobPostingData.jobTitle as string,
              label: jobPostingData.jobTitle as string,
            }}
            onChange={handleJobTitleChange}
            loadOptions={(inputValue, callback) => loadOptionsDebounced(inputValue, callback)}
            isMulti={false}
        />
        <div className='text-gray-500 py-1 text-xs font-medium mt-4'>Working Arrangement</div>
        <CustomSelect
            name='jobWorkType'
            value={{
              label: jobPostingData.workType as string,
              value: jobPostingData.workType as string,
            }}
            options={workTypes}
            onChange={handleChange}
        />
      </div>
      <div className='flex flex-col items-end mt-auto'>
        <div className='text-gray-500 text-sm'>
          <SecondaryTypography.XSmall fontWeight='semi-bold'>
            Publish to Public Website
          </SecondaryTypography.XSmall>
          <ToggleInput
              toggled={jobPostingData.active}
              handleToggle={handleToggle}
              name='Scatter'
              informationText='Enable/Disable'
          />
        </div>
        <div className='mt-4'>
          <Button
              onClick={handleSave}
              variant='primary'
              size='medium'
              disabled={isSaveButtonDisabled}
          >
            Save and Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobPostSettings;
