import React from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../ui/button/button';
import { createJobPost } from '../../../../../../utils/api_service/JobPosting';
import { JobPostingTabData } from '../../../../../../utils/types/JobOpenings';

type PreviewProps = {
  jobPostingData: JobPostingTabData;
};

const Preview = ({ jobPostingData }: PreviewProps) => {
  const createPost = async () => {
    try {
      const updateEmailResponse = await createJobPost({
        department: jobPostingData.jobDepartment,
        workType: jobPostingData.workType,
        jobTitle: jobPostingData.jobTitle,
        htmlTemplate: jobPostingData.htmlTemplate || '',
        jsonTemplate: jobPostingData.jsonTemplate || '',
        startDate: jobPostingData.startDate,
        endDate: jobPostingData.endDate,
        active: jobPostingData.active,
        location: jobPostingData.location,
      });
      if (updateEmailResponse.ok) {
        toast.success('Job Post Successfully Saved!', { position: 'bottom-center' });
      } else {
        throw new Error('Error updating job template');
      }
    } catch (error) {
      toast.error((error as Error).message, { position: 'bottom-center' });
    }
  };

  return (
    <div className='bg-white shadow-lg p-8 rounded-xl max-w-4xl mx-auto border border-gray-300'>
      <h1 className='text-2xl font-semibold text-gray-800 mb-8 text-center'>
        { jobPostingData.jobTitle }
      </h1>

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8'>
        { /* Department */ }
        <div className='flex items-center p-4 bg-gray-100 rounded-md border border-gray-200'>
          <svg className='w-6 h-6 text-gray-600 mr-4' fill='currentColor' viewBox='0 0 24 24'>
            <path d='M20 2H8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H8V4h12v16zM16 6h-8c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 10h-8V8h8v8z' />
          </svg>
          <span className='text-gray-700 text-sm font-medium'>
            <strong>Department:</strong> { jobPostingData.jobDepartment }
          </span>
        </div>

        { /* Location */ }
        <div className='flex items-center p-4 bg-gray-100 rounded-md border border-gray-200'>
          <svg className='w-6 h-6 text-gray-600 mr-4' fill='currentColor' viewBox='0 0 24 24'>
            <path d='M12 2C8.1 2 5 5.1 5 9c0 3.6 3.4 8.7 6.1 11.6.6.7 1.7.7 2.3 0 2.7-2.9 6.1-8 6.1-11.6 0-3.9-3.1-7-7-7zm0 16c-1.2 0-2-.8-2-2s.8-2 2-2 2 .8 2 2-.8 2-2 2z' />
          </svg>
          <span className='text-gray-700 text-sm font-medium'>
            <strong>Location:</strong> { jobPostingData.location }
          </span>
        </div>

        { /* Work Type */ }
        <div className='flex items-center p-4 bg-gray-100 rounded-md border border-gray-200'>
          <svg className='w-6 h-6 text-gray-600 mr-4' fill='currentColor' viewBox='0 0 24 24'>
            <path d='M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM4 20V4h16v16H4z' />
          </svg>
          <span className='text-gray-700 text-sm font-medium capitalize'>
            <strong>Work Type:</strong> { jobPostingData.workType }
          </span>
        </div>

        { /* Dates */ }
        <div className='flex items-center p-4 bg-gray-100 rounded-md border border-gray-200'>
          <svg className='w-6 h-6 text-gray-600 mr-4' fill='currentColor' viewBox='0 0 24 24'>
            <path d='M19 3h-1V2c0-.6-.4-1-1-1h-2c-.6 0-1 .4-1 1v1H10V2c0-.6-.4-1-1-1H7c-.6 0-1 .4-1 1v1H5c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 17H5V9h14v11z' />
          </svg>
          <span className='text-gray-700 text-sm font-medium'>
            <strong>Dates:</strong> { jobPostingData.startDate } - { jobPostingData.endDate }
          </span>
        </div>
      </div>

      <hr className='my-6 border-gray-300' />

      <div
          className='prose max-w-none text-gray-800 leading-relaxed'
          dangerouslySetInnerHTML={{ __html: jobPostingData.htmlTemplate || '' }}
      />

      <div className='mt-8 flex justify-center items-center'>
        <Button
            onClick={createPost}
            variant={'primary'}
            size={'medium'}
        >
          Save and Create
        </Button>
      </div>
    </div>
  );
};

export default Preview;
