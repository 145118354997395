import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { DraggableQuestions } from '../../../../../ui/draggable_questions/draggable_questions';
import { DatePicker } from '../../../../../ui/input/date_picker/date_picker';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { updateAssessment } from '../../../../../utils/api_service/assessment_api';
import { createAutoAssessment, getAutoAssessment } from '../../../../../utils/api_service/JobOpenings';
import { RootState } from '../../../../../utils/redux_store/store';
import { Assessment, BasicInfoInputs } from '../../../../../utils/types/assessment_types';
import { AutoAssessment, JobDescription } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { Tabs } from '../../../../assessment/view/tabs/tabs';
import PreScreenQuestions from '../../../../assessment/view/view_assessment/PreScreenQuestions';
import { Settings } from '../../../../assessment/view/view_assessment/settings/settings';
import LoadingSpinner from '../../ui/LoaderSpinner';
import buttonOverrides from './button-override.module.css';
import { AutoBuild } from './TabContent/AutoBuild';
import { CandidateInvites } from './TabContent/CandidateInvites';
import { InvitationTemplate } from './TabContent/InvitationTemplate';

type AutoAssessmentProps = {
  autoAssessment: AutoAssessment,
  setAutoAssessment: React.Dispatch<React.SetStateAction<AutoAssessment>>,
  jobDescription: JobDescription,
  jobTitle: string,
  completedElement: () => void,
}

export const AutoAssessmentTab = ({
  autoAssessment,
  setAutoAssessment,
  jobDescription,
  jobTitle,
  completedElement,
}: AutoAssessmentProps) => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Quick Build', 'Pre-Screen Questions', 'List of Questions', 'Permissions and Settings', 'Invitation Template', 'Candidates'];
  const skills = useSelector((state: RootState) => state.skills);
  const levels = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);
  const [assessmentInfo, setAssessmentInfo] = useState<Assessment | undefined>(autoAssessment.assessment);
  const [loading, setLoading] = useState<boolean>(true);
  const [assessmentFormInfo, setAssessmentFormInfo] = useState<BasicInfoInputs>({
    title: autoAssessment.assessment.assessmentTitle,
    description: autoAssessment.assessment.description,
    startDate: new Date(autoAssessment.assessment.startDate * 1000).toISOString().split('T')[0],
    endDate: new Date(autoAssessment.assessment.endDate * 1000).toISOString().split('T')[0],
    type: autoAssessment.assessment.assessmentType,
    jobPosition: autoAssessment.assessment.jobPosition || undefined,
  });

  useEffect(() => {
    const processAssessmentData = (data: any) => {
      setAutoAssessment(data);
      setAssessmentInfo(data.assessment);
      setAssessmentFormInfo({
        title: data.assessment.assessmentTitle,
        description: data.assessment.description,
        startDate: new Date(data.assessment.startDate * 1000).toISOString().split('T')[0],
        endDate: new Date(data.assessment.endDate * 1000).toISOString().split('T')[0],
        type: data.assessment.assessmentType,
        jobPosition: data.assessment.jobPosition || undefined,
      });
    };

    const fetchOrCreateAssessment = async () => {
      setLoading(true);
      try {
        if (id === undefined) {
          return;
        }
        let response;
        if (autoAssessment.id.length === 0) {
          response = await createAutoAssessment(autoAssessment);
        } else {
          response = await getAutoAssessment(id);
        }

        if (!response.ok) {
          toast.error('Error Fetching Assessment', { position: 'bottom-center' });
        }

        const data = await response.json();
        processAssessmentData(data);
      } catch (error) {
        toast.error('Error Fetching Assessment', { position: 'bottom-center' });
      } finally {
        setLoading(false);
      }
    };

    fetchOrCreateAssessment();
  }, [id]);

  const updateEmailTemplateState = (updatedJsonTemplate: string) => {
    setAutoAssessment({
      ...autoAssessment,
      emailJsonTemplate: updatedJsonTemplate,
    });
  };

  const changeDate = (timeRange: any) => {
    if (assessmentFormInfo) {
      setAssessmentFormInfo({
        ...assessmentFormInfo,
        startDate: timeRange.startDate,
        endDate: timeRange.endDate,
      });
    }
  };

  const handleDataChange = async () => {
    if (assessmentFormInfo) {
      const response = await updateAssessment(assessmentFormInfo, autoAssessment.assessment.id);
      if (response[0].id) {
        setAssessmentFormInfo(response);
        toast.success('Assessment Details Updated!', { position: 'bottom-center' });
      } else {
        toast.error('Error Updating Assessment', { position: 'bottom-center' });
      }
    }
  };

  const renderTabContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (activeTab === 0) {
      return <AutoBuild
          autoAssessment={autoAssessment}
          setAutoAssessment={setAutoAssessment}
          skills={[...(jobDescription?.desirableSkills || []), ...(jobDescription?.mandatorySkills || [])]}
          jobTitle={jobTitle}
          completedElement={completedElement}
      />;
    } else if (activeTab === 1 && assessmentInfo) {
      return (
        <PreScreenQuestions
            assessmentInfo={assessmentInfo}
            setAssessmentInfo={setAssessmentInfo}
        />
      );
    } else if (activeTab === 2) {
      return (
        <div
            style={{ margin: '20px 0' }}
        >
          <DraggableQuestions
              assessmentId={autoAssessment.assessment.id}
              skillList={skillList}
              levelList={levels.levelList}
              jobTitleList={jobTitles.jobTitlesList}
              subSkillList={subSkills.subSkillsList}
              setSkillList={setSkillList}
              completedElement={completedElement}
          />
        </div>
      );
    } else if (activeTab === 3) {
      return (
        <>
          { assessmentFormInfo && (
            <div className='mt-3.5'>
              <SecondaryTypography.Small
                  fontWeight='semi-bold'
                  className='mt-2'
              >
                Change Assessment Date
              </SecondaryTypography.Small>
              <div
                  className='flex flex-row w-3/6 items-end'
              >
                <DatePicker
                    label='Time-Range'
                    startDate={assessmentFormInfo.startDate}
                    endDate={assessmentFormInfo.endDate}
                    onChange={changeDate}
                />
                <Button
                    variant='primary'
                    size='small'
                    customClass={buttonOverrides.overrideClass}
                    onClick={handleDataChange}
                >
                  Save Assessment Duration
                </Button>
              </div>
            </div>
          ) }
          <div className='h-3 w-full rounded-full bg-slate-200 my-5'/>
          <Settings
              assessmentType={'candidate'}
              assessmentId={autoAssessment.assessment.id}
          />
        </>
      );
    } else if (activeTab === 4) {
      return <InvitationTemplate emailTemplate={autoAssessment.emailJsonTemplate} updateEmailTemplateState={updateEmailTemplateState}/>;
    } else if (activeTab === 5) {
      return <CandidateInvites />;
    }
  };

  return (
    <div>
      <Tabs
          tabTitles={tabTitles}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
      />
      { renderTabContent() }
    </div>
  );
};
