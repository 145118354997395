import React, { useState } from 'react';

interface Person {
    id: string;
    name: string;
    profileImage?: string;
    score: number;
    speed: string;
    totalScore: number;
    dominantEmotion: string;
    informationList: { [key: string]: { [subskill: string]: any; total: any  } };
    questionAnswer: { [key: string] : {answer: string}}
}

interface ResultsScoreCardProps {
    data: Person[];
    renderQuestion: boolean
}

const ResultsScoreCard: React.FC<ResultsScoreCardProps> = ({ data, renderQuestion }) => {
  const [startIndex, setStartIndex] = useState(0); // Index of the first visible card
  const [expandedQuestions, setExpandedQuestions] = useState<Set<string>>(new Set()); // State to track expanded questions
  const visibleCards = 3; // Number of cards visible at a time
  const displayQuestionAnswer = true

  const handleNext = () => {
    // Move forward, but stop when reaching the end
    setStartIndex((prev) => Math.min(prev + 1, data.length - visibleCards));
  };

  const handlePrevious = () => {
    // Move backward, but stop at the beginning
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleToggleExpand = (question: string) => {
    setExpandedQuestions((prevExpandedQuestions) => {
      const newExpandedQuestions = new Set(prevExpandedQuestions);
      if (newExpandedQuestions.has(question)) {
        newExpandedQuestions.delete(question);
      } else {
        newExpandedQuestions.add(question);
      }
      return newExpandedQuestions;
    });
  };

  return (
    <div className='flex items-center'>
      { /* Left Arrow */ }
      { ( startIndex !== 0 &&
      <button
          onClick={handlePrevious}
          disabled={startIndex === 0}
          className={`p-2 text-xl bg-gray-200 rounded-full ${
            startIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
      >
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 text-gray-700'
            viewBox='0 0 20 20'
            fill='currentColor'
        >
          <path
              fillRule='evenodd'
              d='M12.707 14.707a1 1 0 01-1.414 0L7 10.414a1 1 0 010-1.414l4.293-4.293a1 1 0 111.414 1.414L8.414 10l4.293 4.293a1 1 0 010 1.414z'
              clipRule='evenodd'
          />
        </svg>
      </button>
      ) }

      { /* Visible Cards */ }
      <div className='flex gap-4 overflow-hidden w-full'>
        { /* Title Column */ }
          <div className='w-64 border rounded-lg shadow-lg  flex flex-col border border-black'>
              <div className='text-center'>
                  <h2 className='font-semibold text-lg mt-2'>Full Name</h2>
              </div>

              <div className='mt-[5rem]'>
                  <div className='bg-[#E8B525] text-center py-1 font-semibold'>
                      Dominant Emotion
                  </div>
                  <div>
                      {renderQuestion ? (
                          <div className='mt-6 border border-black'>
                              <div className='mt-2 text-center font-semibold'>Overall Score</div>
                              <div className='mt-2 mb-2 text-center font-semibold'>Overall Speed</div>
                          </div>
                      ) : (
                          <div className='mt-2 text-center text-lg font-bold'>Total Score</div>
                      )}
                  </div>
              </div>


              { /* Title Rows for Questions/Subskills */}
              <div>
                  {renderQuestion ? (
                      <div className='mt-6 space-y-2'>
                          {Object.entries(data[0].questionAnswer).map(([question, answer], idx) => (
                              <div key={idx} className='py-1.5 px-0.5 bg-[#E5E5E5]'>
                                  <div className='font-medium bg-[#E5E5E5]'>
                                      {question.length > 60 ? `${question.slice(0, 57)}...` : question}
                                  </div>
                              </div>
                          ))}
                      </div>
                  ) : (
                      <div className='mt-4 space-y-2'>
                          {Object.entries(data[0].informationList).map(([question, subskills]) => {
                              const isExpanded = expandedQuestions.has(question);

                              return (
                                  <div key={question}>
                                      <div
                                          className='font-medium text-center text-gray-600 cursor-pointer'
                                          onClick={() => handleToggleExpand(question)}
                                      >
                                          <span>{question}</span>
                                          <span className='ml-2 text-sm'>{isExpanded ? '[-]' : '[+]'}</span>
                                      </div>
                                      {isExpanded && (
                                          <div className='space-y-1 mt-2'>
                                              {Object.entries(subskills).map(([subskill, _], idx) => {
                                                  if (subskill !== 'total') {
                                                      return (
                                                          <div key={idx}
                                                               className='flex justify-center text-sm text-gray-500 mt-1'>
                                                              <span>{subskill}</span>
                                                          </div>
                                                      );
                                                  }
                                              })}
                                          </div>
                                      )}
                                  </div>
                              );
                          })}
                      </div>
                  )}
              </div>

          </div>

          { /* Candidate Cards */}
          {data.slice(startIndex, startIndex + visibleCards).map((person) => (
              <div
                  key={person.id}
                  className='w-64 border rounded-lg shadow-lg flex flex-col border border-black'
              >
                  <div className='text-center'>
                      <h2 className='font-semibold text-lg mt-2'>{person.name}</h2>
                      <img
                          src={person.profileImage || 'https://via.placeholder.com/150'}
                          alt={person.name}
                          className='w-16 h-16 rounded-full mx-auto'
                      />
                  </div>

                  <div className='mt-4'>
                      <div className='bg-[#E8B525] text-center py-1  font-semibold'>
                          {person.dominantEmotion}
                      </div>
                      <div>
                          {renderQuestion ? (
                              <div className='mt-6 border'>
                                  <div className='bg-green-500 text-center mt-2 font-semibold'>80%</div>
                                  <div className='bg-green-500 text-center mt-2 mb-2 font-semibold'>60%</div>
                              </div>
                          ) : (
                              <div className='mt-2 text-center text-lg font-bold'>{person.totalScore}</div>
                          )}
                      </div>
                  </div>

                  { /* Render Candidate Values with Color Coding */}
                  <div>
                      {renderQuestion ? (
                          <div className='mt-6 space-y-2'>
                              {Object.entries(person.questionAnswer).map(([question, answerObj], idx) => (
                                  <div key={idx} className='py-1.5 px-0.5 bg-[#E5E5E5]'>
                                      <div>
                                          <div className='font-medium bg-[#E5E5E5]'>
                                              {answerObj.answer.length > 60
                                                  ? `${answerObj.answer.slice(0, 57)}...`
                                                  : answerObj.answer}
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      ) : (
                          <div className='mt-[2.4rem] space-y-6'>
                              {Object.entries(person.informationList).map(([question, subskills]) => {
                                  const isExpanded = expandedQuestions.has(question);

                                  return (
                                      <div key={question}>
                                          {isExpanded &&
                                              Object.entries(subskills).map(([subskill, score], idx) => {
                                                  if (subskill !== 'total') {
                                                      return (
                                                          <div
                                                              key={idx}
                                                              className={`w-full text-center py-1 text-sm mt-1 ${
                                                                  score >= 50 ? 'bg-[#3BB54A]' : 'bg-[#D82A27]'
                                                              }`}
                                                          >
                                                              {score}%
                                                          </div>
                                                      );
                                                  }
                                              })}
                                      </div>
                                  );
                              })}
                          </div>
                      )}
                  </div>
              </div>
          ))}
      </div>

        { /* Right Arrow */}
        {(startIndex < data.length - visibleCards &&
            <button
                onClick={handleNext}
                disabled={startIndex >= data.length - visibleCards}
                className={`p-2 text-xl bg-gray-200 rounded-full ${
                    startIndex >= data.length - visibleCards
              ? 'opacity-50 cursor-not-allowed'
              : ''
          }`}
      >
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-5 w-5 text-gray-700'
            viewBox='0 0 20 20'
            fill='currentColor'
        >
          <path
              fillRule='evenodd'
              d='M7.293 14.707a1 1 0 001.414 0L13 10.414a1 1 0 000-1.414L8.707 4.707a1 1 0 10-1.414 1.414L11.586 10l-4.293 4.293a1 1 0 000 1.414z'
              clipRule='evenodd'
          />
        </svg>
      </button>
      ) }

    </div>
  );
};

export default ResultsScoreCard;
