import React, { useEffect, useState } from 'react';
import { PaginationNavigator } from '../../../../ui/PaginationNavigator';
import { getJobPostList } from '../../../../utils/api_service/JobPosting';
import { FilterState, JobPostingData } from '../../../../utils/types/JobOpenings';
import JobPostingFilter from '../JobPostingFilter';
import JobCard from './JobCard';

const JobPostTable = () => {
  const [filters, setFilters] = useState<FilterState>({
    jobTitle: [],
    location: [],
    workType: [],
    department: [],
  });

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [search, setSearch] = useState<boolean>(true);
  const [jobPostData, setJobPostData] = useState<JobPostingData[]>([]);
  const limit = 6;
  const [maxPages, setMaxPages] = useState<number>(1);

  useEffect(() => {
    const fetchJobPost = async () => {
      try {
        const response = await getJobPostList(
          filters.jobTitle,
          filters.location,
          filters.department,
          filters.workType,
          pageNumber,
          limit,
        );
        const responseData = await response.json();
        setJobPostData(responseData.results);
        setMaxPages(Math.ceil(responseData.total / limit));
      } catch (error) {
        console.error('Error fetching job post:', error);
      }
    };

    fetchJobPost();
  }, [search, pageNumber]);

  return (
    <div>
      <div className='p-4'>
        <div>
          <JobPostingFilter
              filters={filters}
              setFilters={setFilters}
              setSearch={setSearch}
              search={search}
          />
        </div>
        <div className='bg-gray-100'>
          <div className='flex flex-wrap gap-6 justify-center mt-10'>
            { jobPostData.map((job, index) => (
              <JobCard key={index} jobPostData={job} />
            )) }
          </div>
        </div>
        { jobPostData.length > 0 && (
          <div className='ml-auto mr-auto mt-5'>
            <PaginationNavigator
                pageNumber={pageNumber}
                maxPageNumber={maxPages}
                setPageNumber={setPageNumber}
            />
          </div>
        ) }
      </div>
    </div>
  );
};

export default JobPostTable;
