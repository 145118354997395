import React from 'react';
import { useHistory } from 'react-router-dom';
import { JobPostingData } from '../../../../../utils/types/JobOpenings';

const extractRoleDetails = (htmlTemplate: string): string | null => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlTemplate, 'text/html');

  // Select the element containing "Role Details" and retrieve all following paragraphs until the next <strong> tag
  const roleDetailsTitle = Array.from(doc.querySelectorAll('p > strong'))
    .find(el => el.textContent === 'Role Details');

  if (roleDetailsTitle) {
    const roleDetailsContent: string[] = [];
    let sibling = roleDetailsTitle.parentElement?.nextElementSibling;

    // Collect all <p> elements following "Role Details" until we hit a new <strong> section
    while (sibling && !sibling.querySelector('strong')) {
      roleDetailsContent.push(sibling.outerHTML);
      sibling = sibling.nextElementSibling;
    }

    return roleDetailsContent.join('');
  }
  return null; // Return null if Role Details section is not found
};

type JobCardProps = {
    jobPostData: JobPostingData,
}

const JobCard = ({ jobPostData }: JobCardProps) => {

  const history = useHistory();
  const roleDetails = extractRoleDetails(jobPostData.htmlTemplate);

  return (
    <div
        className='max-w-md w-full bg-gradient-to-br from-gray-50 to-gray-100 shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300 ease-in-out'>
      <div className='flex items-center mb-4'>
        <div className='w-12 h-12 bg-gray-200 rounded-full mr-4 flex items-center justify-center text-gray-400'>
          <svg xmlns='http://www.w3.org/2000/svg' className='w-6 h-6' fill='none' viewBox='0 0 24 24'
              stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}
                d='M3 7h18M6 7V5a2 2 0 012-2h8a2 2 0 012 2v2m3 0a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V9a2 2 0 012-2m3 0h8'/>
          </svg>
        </div>
        <div>
          <h2 className='text-2xl font-semibold text-gray-800'>{ jobPostData.jobTitle }</h2>
          <p className='text-sm text-gray-500'>{ jobPostData.department }</p>
        </div>
      </div>
      <div className='flex justify-between items-center mb-2'>
        <div className='flex items-center text-gray-600 text-sm'>
          <svg xmlns='http://www.w3.org/2000/svg' className='w-4 h-4 mr-1 text-gray-500' fill='none'
              viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}
                d='M12 2C8.1 2 5 5.1 5 9c0 4.5 7 13 7 13s7-8.5 7-13c0-3.9-3.1-7-7-7zm0 9a2 2 0 100-4 2 2 0 000 4z'/>
          </svg>
          <p>{ jobPostData.location }</p>
        </div>
        <span
            className={`px-3 py-1 text-xs font-medium rounded-full 
          ${jobPostData.workType === 'Remote' ? 'bg-green-100 text-green-700' :
              jobPostData.workType === 'Office' ? 'bg-blue-100 text-blue-700' :
                'bg-yellow-100 text-yellow-700'}`}
        >
          { jobPostData.workType }
        </span>
      </div>
      <p className='text-gray-500 text-xs mb-4'>Posted on: <span
          className='font-semibold'>{ new Date(jobPostData.startDate * 1000).toLocaleDateString() }</span></p>
      <p className='text-gray-700 mb-4 line-clamp-3'
          dangerouslySetInnerHTML={{ __html: roleDetails || 'Role details not available.' }}/>
      <button
          onClick={() => history.push(`/job-posting-list/${jobPostData.id}`)}
          className='w-full mt-4 flex items-center justify-center bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200'
      >
        <span className='mr-2'>
          Apply
        </span>
        <svg xmlns='http://www.w3.org/2000/svg' className='w-5 h-5' fill='none' viewBox='0 0 24 24'
            stroke='currentColor'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}
              d='M3 7h18M6 7V5a2 2 0 012-2h8a2 2 0 012 2v2m3 0a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V9a2 2 0 012-2m3 0h8'/>
        </svg>
      </button>
    </div>
  );

};

export default JobCard;
