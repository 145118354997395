import React, { useEffect } from 'react';
import { ToggleInput } from '../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { JobApplicantCSV, JobApplicantSearchRequest } from '../../../../../utils/types/JobOpenings';
import { TopicInput } from '../../../../questions/upload_file/topic_input';

type Analytic3DProps = {
    scatter: boolean,
    setScatter: (value: boolean) => void,
    view3D: boolean,
    setView3D: (value: boolean) => void,
    markLines: boolean,
    setMarkLines: (value: boolean) => void,
    nineBox: boolean,
    setNineBox: (value: boolean) => void,
    searchObject: JobApplicantSearchRequest,
    setSearchObject: (jobApplicantSearchRequest: JobApplicantSearchRequest) => void,
    searchCSVObject: JobApplicantCSV,
    setSearchCSVObject: (JobApplicantCSV: JobApplicantCSV) => void,
    setSimilarKeyword: React.Dispatch<React.SetStateAction<string>>;
    similarKeyword: string,
    setExactKeyword: React.Dispatch<React.SetStateAction<string>>;
    exactKeyword: string,
}

export const Analytic3D = ({
  scatter,
  setScatter,
  view3D,
  setView3D,
  markLines,
  setMarkLines,
  nineBox,
  setNineBox,
  searchObject,
  setSearchObject,
  searchCSVObject,
  setSearchCSVObject,
  setSimilarKeyword,
  similarKeyword,
  setExactKeyword,
  exactKeyword,
}: Analytic3DProps) => {

  useEffect(() => {

    const similarList = similarKeyword.split(':').filter(Boolean);
    const exactList = exactKeyword.split(':').filter(Boolean);
    const mergedList = [...similarList, ...exactList];

    setSearchObject({
      ...searchObject,
      keywords: similarKeyword && exactKeyword === ''? []:mergedList,
    });
    setSearchCSVObject({
      ...searchCSVObject,
      keywords: similarKeyword && exactKeyword === ''? []:mergedList,
    });
  }, [similarKeyword, exactKeyword]);

  const convertStringToObjects = (input: string): { label: string, value: string }[] => {
    if (input === '') {
      return [];
    } else {
      const value = input.split(':').map(item => ({
        label: item,
        value: item,
      }));
      return value;
    }
  };

  return (
    <div
        className='mt-12 flex flex-col gap-0 border-b border-gray-200'
    >
      <ToggleInput
          toggled={nineBox}
          handleToggle={() => setNineBox(!nineBox)}
          name='edit'
          informationText='9 Box'
      />
      <ToggleInput
          toggled={markLines}
          handleToggle={() => setMarkLines(!markLines)}
          name='edit'
          informationText='Grid Lines'
      />
      <ToggleInput
          toggled={view3D}
          handleToggle={() => setView3D(!view3D)}
          name='3DView'
          informationText='3D View'
      />
      { view3D && (
        <>
          <div>
            <SecondaryTypography.XSmall
                className='text-gray-500 pt-1'
                fontWeight='semi-bold'>
              Z: Keywords Counter (Match on Exact)
            </SecondaryTypography.XSmall>
            <TopicInput
                className='max-w-[250px]'
                setTopic={setExactKeyword}
                disableSkill={true}
                result={convertStringToObjects(exactKeyword)}
            />
            <SecondaryTypography.XSmall
                className='text-gray-500 pt-1'
                fontWeight='semi-bold'>
              Z: Keywords Counter (Match on Similar)
            </SecondaryTypography.XSmall>
            <TopicInput
                className='max-w-[250px]'
                setTopic={setSimilarKeyword}
                disableSkill={true}
                result={convertStringToObjects(similarKeyword)}
            />
            <ToggleInput
                toggled={scatter}
                handleToggle={() => setScatter(!scatter)}
                name='Scatter'
                informationText='Scatter/Bar'
            />
          </div>
        </>
      ) }
    </div>
  );
};
