import React, { useState } from 'react';
import { PieItem } from '../../../../../utils/types/JobOpenings';
import { Tabs } from '../../../../assessment/view/tabs/tabs';
import { NumberInfo } from '../../ui/Charts/NumberInfo';
import { PieChart } from '../../ui/Charts/PieChart';
import UpcomingInterviews from '../ui/UpcomingInterviews';
import Candidates from './Candidates';
import Configurations from './Configurations';
import InvitationTemplateTab from './InvitationTemplateTab';
import Questions from './Questions';
import Results from './Results';

type GenderGroups = {
    Male: number;
    Female: number;
    'Non-binary': number;
    Unknown: number;
};

const RoundOne = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [tabTitles, setTabTitles] = useState<string[]>(['Configurations', 'Invitation Template', 'Candidates', 'Questions', 'Results']);
  const [activeTab, setActiveTab] = useState<number>(0);

  const interviews = [
    { candidateName: 'Michael Cooray', interviewDate: '2024-12-20' },
    { candidateName: 'Akmal Hameed', interviewDate: '2024-12-21' },
    { candidateName: 'Bilal Naazir', interviewDate: '2024-12-22' },
  ];

  const handleChartClick = (chartName: string) => {
    console.log('test');
  };

  const getGenderData = (): PieItem[] => {
    const genderGroups: GenderGroups = {
      'Male': 0,
      'Female': 0,
      'Non-binary': 0,
      'Unknown': 0,
    };

    // Dummy data for demonstration purposes
    const applicants = [
      { gender: 'Male' },
      { gender: 'Female' },
      { gender: 'Non-binary' },
      { gender: 'Male' },
      { gender: 'Female' },
      { gender: 'Unknown' },
      { gender: 'Male' },
    ];

    // Process the applicants and count genders
    applicants.forEach((applicant) => {
      // Cast applicant.gender to keyof GenderGroups to ensure it's a valid key
      const gender = applicant.gender as keyof GenderGroups;

      if (gender in genderGroups) {
        genderGroups[gender]++;
      } else {
        genderGroups['Unknown']++; // For any unknown gender values
      }
    });

    // Convert the genderGroups object into an array of name-value pairs
    return Object.entries(genderGroups).map(([name, value]) => ({
      name,
      value,
    }));
  };

  const renderTab = () => {
    if (activeTab === 0) {
      return (
        <Configurations/>
      );
    } else if (activeTab === 1) {
      return (
        <InvitationTemplateTab/>
      );
    } else if (activeTab === 2) {
      return (
        <Candidates/>
      );
    } else if (activeTab === 3) {
      return (
        <div className='my-5'>
          <Questions/>
        </div>
      );
    } else {
      return (
        <Results/>
      );
    }

  };

  return (
    <>
      <div>
        <div className='flex justify-between gap-4 ml-[3vw] mr-[1.5vw]'>
          <NumberInfo
              value={10}
              name={'R1 ShortList'}
              loading={loading}
          />
          <PieChart
              name={'invitation status'}
              pieData={getGenderData()}
              onClick={() => handleChartClick('Gender')}
              loading={loading}
          />
          <NumberInfo
              value={20}
              name={'Days to deadline'}
              loading={loading}
          />
          <NumberInfo
              value={5}
              name={'R2 Shortlist'}
              loading={loading}
          />
          <div className=''>
            <UpcomingInterviews interviews={interviews}/>
          </div>
        </div>
        <div className='py-2'>
          <Tabs
              tabTitles={tabTitles}
              setTabTitles={setTabTitles}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
          />
        </div>

        { renderTab() }
      </div>
    </>
  );
};

export default RoundOne;
