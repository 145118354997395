import { createRequest } from '../helpers/api_utils';
import { workArrangement } from '../types/JobOpenings';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/job-posting';

export async function createJobPost(body: { department: string, jobTitle: string, workType: workArrangement, jsonTemplate: string, htmlTemplate: string, startDate: string, endDate: string, active: boolean, location: string }) {
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export async function getJobPost(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'GET', 'application/json', true));
}

export function getJobPostList(job_title: string[] = [], location: string[] = [], department: string[] = [], workType: string[] = [], page: number = 1, limit: number = 10) {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('limit', limit.toString());

  if (job_title.length > 0) params.append('job_title', job_title.join(','));
  if (location.length > 0) params.append('location', location.join(','));
  if (department.length > 0) params.append('department', department.join(','));
  if (workType.length > 0) params.append('work_type', workType.join(','));

  const url = `${apiEndpoint}/list?${params.toString()}`;
  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export function getJobFilters() {
  return fetch(createRequest(apiEndpoint + '/search/filters', 'GET', 'application/json', true));
}
