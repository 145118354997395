import React, {  useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { assessmentTypes } from '../../../../../../utils/analytics_data/filterData';
import { RootState } from '../../../../../../utils/redux_store/store';
import { AssessmentsSearchObject } from '../../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';

type AssessmentFilterProps = {
    searchObject: AssessmentsSearchObject,
    setSearchObject: (searchObject: AssessmentsSearchObject) => void,
};

const AssessmentFilter = ({ searchObject, setSearchObject } : AssessmentFilterProps) => {

  const skills = useSelector((state: RootState) => state.skills);
  const [assessmentList, setAssessmentList] = useState<SelectOptions[]>(skills.skillsList);

  const handleTimeChange = (timeRange: DateValueType) => {
    setSearchObject({
      ...searchObject,
      timeRange,
    });
  };

  const handleAssessmentNameChange = (action: any, actionValues: any) => {
    console.log(action);
    setSearchObject({
      ...searchObject,
      [actionValues.name]: action? action.value.charAt(0).toUpperCase() + action.value.slice(1) : '',
    });
  };

  return <div className='flex flex-col md:flex-row md:flex-wrap md:items-center pb-4 bg-primaryWhite100 py-4 gap-4'>
    <div>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        Time Range
      </SecondaryTypography.Small>
      <Datepicker
          displayFormat='DD/MM/YYYY'
          containerClassName='border border-gray-300 rounded-md w-full relative z-[11]'
          showShortcuts={true}
          onChange={handleTimeChange}
          value={searchObject.timeRange}
      />
    </div>
    <div>
      <SecondaryTypography.Small
          keepDefaultMargins={true}
      >
        Assessment Type
      </SecondaryTypography.Small>
      <Select
          className='min-w-[250px] max-w-[400px] z-11'
          placeholder='Select Assessment Type'
          name='assessmentType'
          options={assessmentTypes}
          onChange={handleAssessmentNameChange}
          isClearable={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
      />
    </div>

  </div>;
};

export default AssessmentFilter;
