import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { AddIcon } from '../../../../ui/icons/icons';
import { Modal } from '../../../../ui/modal/modal';
import { getEmployeeList } from '../../../../utils/api_service/employees';
import { addPartner, deletePartner, editPartner, getPartnerEmployees, getPartnerList } from '../../../../utils/api_service/partner_api';
import { EmployeeUser, PartnerEntity, PartnerUser } from '../../../../utils/types/admin_types';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import EntityTree from '../EntityTree';
import { AddPartnerModal } from './Modals/AddPartnerModal';
import { EditPartnerModal } from './Modals/EditPartnerModal';

export const EntityManagement = () => {
  const [partnerList, setPartnerList] = useState<PartnerEntity[]>();
  const [selectedEntity, setSelectedEntity] = useState<PartnerEntity>();
  const [employees, setEmployees] = useState<EmployeeUser[]>();
  const [partnerEmployees, setPartnerEmployees] = useState<PartnerUser[]>();
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await getPartnerList({ subEntities: true });
        if (response.ok) {
          const data = await response.json();
          setPartnerList(data);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching products: ' + error, { position: 'bottom-center' });
      }
    };

    const fetchEmployees = async () => {
      try {
        const response = await getEmployeeList('', 1, 10);
        if (response.ok) {
          const data = await response.json();
          setEmployees(data.employee);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching employees: ' + error, { position: 'bottom-center' });
      }
    };

    const fetchPartnerEmployees = async () => {
      try {
        const response = await getPartnerEmployees({ page: 1, recordsPerPage: 10 });
        if (response.ok) {
          const data = await response.json();
          setPartnerEmployees(data.partner);
        } else {
          const errorData = await response.json();
          toast.error(errorData.error, { position: 'bottom-center' });
        }
      } catch (error) {
        toast.error('Error fetching partner employees: ' + error, { position: 'bottom-center' });
      }
    };

    fetchPartners();
    fetchEmployees();
    fetchPartnerEmployees();
  }, []);

  const onAddClick  = (entity: PartnerEntity) => {
    setAddModalVisible(true);
    setSelectedEntity(entity);
  };

  const onEditClick  = async (entity: PartnerEntity) => {
    try {
      const response = await getPartnerList({ id: entity.id });
      if (response.ok) {
        const data = await response.json();
        data.subEntities = entity.subEntities;
        setSelectedEntity(data);
        setEditModalVisible(true);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error fetching entity: ' + error, { position: 'bottom-center' });
    }
  };

  const deleteEntity = async (entity: PartnerEntity) => {
    try {
      const response = await deletePartner(entity.id);
      if (response.ok && partnerList) {
        setPartnerList(deleteEntityById(partnerList, entity.id));
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error deleting entity: ' + error, { position: 'bottom-center' });
    }
  };

  const deleteEntityById = (state: PartnerEntity[], entityId: string): PartnerEntity[] => {
    const updatedState: PartnerEntity[] = state.filter(entity => entity.id !== entityId);
    function deleteSubEntity(entity: PartnerEntity): PartnerEntity | null {
      if (entity.subEntities) {
        entity.subEntities = entity.subEntities.map(subEntity => deleteSubEntity(subEntity)).filter(Boolean) as PartnerEntity[];
      }
      return entity.id !== entityId ? entity : null;
    }
    updatedState.forEach(entity => {
      if (entity.subEntities) {
        entity.subEntities = entity.subEntities.map(subEntity => deleteSubEntity(subEntity)).filter(Boolean) as PartnerEntity[];
      }
    });
    return updatedState;
  };

  const editEntityById = async (state: PartnerEntity[], editedEntity: PartnerEntity, internalManagers: SelectOptions[], externalManagers: SelectOptions[]) => {
    editedEntity = {
      ...editedEntity,
      partnerManagersInternal: internalManagers.length > 0 ? internalManagers.map(option => option.value) : [],
      partnerManagersExternal: externalManagers.length > 0 ? externalManagers.map(option => option.value) : [],
    };

    const updateSubEntities = (entities: PartnerEntity[]): PartnerEntity[] => {
      return entities.map(entity => {
        if (entity.subEntities) {
          entity.subEntities = updateSubEntities(entity.subEntities);
        }
        return entity.id === editedEntity.id ? { ...entity, ...editedEntity } : entity;
      });
    };

    try {
      const response = await editPartner(editedEntity);
      if (response.ok && partnerList) {
        const updatedState: PartnerEntity[] = updateSubEntities([...state]);
        setPartnerList(updatedState);
        setEditModalVisible(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error editing entity: ' + error, { position: 'bottom-center' });
    }
  };

  const addEntityToSubEntities = async (state: PartnerEntity[], parentId: string, newEntity: PartnerEntity, internalManagers: SelectOptions[], externalManagers: SelectOptions[]) => {
    newEntity = {
      ...newEntity,
      partnerManagersInternal: internalManagers.length > 0 ? internalManagers.map(option => option.value) : [],
      partnerManagersExternal: externalManagers.length > 0 ? externalManagers.map(option => option.value) : [],
    };

    try {
      const response = await addPartner(newEntity);
      if (response.ok && partnerList) {
        const data = await response.json();
        if (parentId.length > 0) {
          const updatedState: PartnerEntity[] = updateParentSubEntities([...state], parentId, data);
          setPartnerList(updatedState);
          setAddModalVisible(false);
        } else {
          const updatedState: PartnerEntity[] = [...partnerList, data];
          setPartnerList(updatedState);
          setAddModalVisible(false);
        }
      } else {
        const errorData = await response.json();
        toast.error(errorData.error, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error('Error adding entity: ' + error, { position: 'bottom-center' });
    }
  };

  const updateParentSubEntities = (entities: PartnerEntity[], parentId: string, newEntity: PartnerEntity): PartnerEntity[] => {
    return entities.map(entity => {
      if (entity.id === parentId) {
        return { ...entity, subEntities: [...(entity.subEntities || []), newEntity] };
      } else if (entity.subEntities && entity.subEntities.length > 0) {
        return { ...entity, subEntities: updateParentSubEntities(entity.subEntities, parentId, newEntity) };
      }
      return entity;
    });
  };

  return (
    <>
      <Button
          icon={<AddIcon/>}
          onClick={() => { setSelectedEntity(undefined);
            setAddModalVisible(true);}}
          variant='primary'
          size='medium'
      >
        Add Partner
      </Button>
      <EntityTree
          entities={partnerList}
          onAddClick={onAddClick}
          onDeleteClick={deleteEntity}
          onEditClick={onEditClick}
      />
      <Modal
          visible={editModalVisible}
          modalContent={
            <EditPartnerModal
                entityList={partnerList || []}
                entity={selectedEntity}
                employees={employees || []}
                partnerEmployees={partnerEmployees || []}
                editPartnerEntity={editEntityById}
            />
          }
          toggle={() => setEditModalVisible(false)}
          headerText='Edit Partner Entity'
          // headerClass={commonStyles.modalHeader}
          // customClass={commonStyles.organizationSettingsModal}
      />
      <Modal
          visible={addModalVisible}
          modalContent={
            <AddPartnerModal
                entityList={partnerList || []}
                entity={selectedEntity}
                employees={employees || []}
                partnerEmployees={partnerEmployees || []}
                addPartnerEntity={addEntityToSubEntities}
            />
          }
          toggle={() => setAddModalVisible(false)}
          headerText='Add Partner Entity'
          // headerClass={commonStyles.modalHeader}
          // customClass={commonStyles.organizationSettingsModal}
      />
    </>
  );
};
