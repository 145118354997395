import React from 'react';
import { useHistory } from 'react-router-dom';
import LogoWhite from '../../assets/images/logos/white-bg-logo-hiacuity.png';
import { deleteCurrentUser } from '../../utils/api_service/user_api';
import { SideBarProfile } from '../sidebar/sidebar_profile/sidebar_profile';
import ViewOption from './ViewOption';

export const NavBar = () => {
  const history = useHistory();

  // TODO(Joseph): Add log-out function to api-services once back-end endpoint is complete.
  const logOut = () => {
    localStorage.clear();
    history.push('/login');
  };

  const deleteUser = () => {
    deleteCurrentUser().then(response => {
      if (response.ok) {
        logOut();
      }
    });
  };

  return (
    <div
        className='h-16 flex justify-between items-center px-4 py-2 w-full border'
    >
      <div className='flex items-center'>
        <img
            src={(localStorage.getItem('companyLogo') ?? LogoWhite) as string}
            className='w-36 object-contain'
            alt='white-logo'
        />
      </div>
      <div className='flex items-center gap-4'>
        <ViewOption/>
        <SideBarProfile
            logout={logOut}
            deleteUser={deleteUser}
        />
      </div>

    </div>
  );
};
