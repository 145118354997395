import { EmployeeUser, JobTitle } from './admin_types';
import { Assessment } from './assessment_types';

export type jobOpeningTypes = 'Job Opening Details' | 'Job Posting' | 'Applicant Analysis'| 'Auto-Suitability' | 'Auto-Outstanding' | 'Auto-Assessments' | 'Interviews' | 'Analytics';
export type jobOpeningDetailsTabs = 'Job Requisition' | 'Job Description' | 'Job Posting' | 'CV Ingestion' | 'Auto Suitability' | 'Auto Outstanding' | 'Auto Assessment';
export type InterviewTabs = 'Round 1' | 'Offer' | 'Add Round';
export type jobOpeningPages = 'Job Opening Details' | 'Job Posting' | 'Applicant Analysis'| 'Interviews' | 'Analytics';
export type applicantScopeType = 'Internal' | 'External';
export type workType = 'Permanent' | 'Part Time' | 'Casual';
export type workArrangement = 'Hybrid' | 'Remote' | 'Office';

export type showAllTypes = 'showAll' | 'collapseAll' | 'random';
export type JobReferenceNumberTypes = 'Auto' | 'Manual' | 'JD';
export const AutoBuildCompleteStatus = 'Generated Questions for all skills';

export type JobRequisition = {
  id: string
  jobRequisitionType: 'New Role' | 'Replacement',
  applicantScope: applicantScopeType[],
  jobOpeningId: string,
  hiringManager: string[] | EmployeeUser[],
  deadline: number | string,
  internalRecruiter: string[] | EmployeeUser[],
  headCount: number
}

export type JobDescription = {
  id: string,
  jobTitle?: JobTitle | string,
  filename?: string,
  jobOpeningId: string,
  jobReferenceNumber?: string,
  jobReferenceNumberJD?: string,
  jobLocation?: string,
  workType?: workType,
  responsibilities?: string[],
  educations?: string[],
  certifications?: string[],
  mandatorySkills?: string[],
  desirableSkills?: string[],
  status?: string,
  errorMessage?: string,
  transcript?: string,
  completed?: boolean,
  minimumWorkExperience?: number,
  jobReferenceType: JobReferenceNumberTypes,
  jobReferenceNumberAuto: string
}

export type JobPostingTabData = {
  jobTitle: string;
  location: string;
  jobDepartment: string;
  workType: workArrangement;
  htmlTemplate?: string;
  jsonTemplate?: string;
  active: boolean;
  startDate: string;
  endDate: string;
}

export type AutoEvaluationLabel = {
    value: string;
    relationToNext: 'AND' | 'OR';
}

export type AutoEvaluationLabelWithNumber = {
    value: number,
    operator: 'Greater Than' | 'Lesser Than',
}

export type AutoEvaluation = {
  id: string,
  jobOpeningId: string,
  jobLocation: string,
  age: AutoEvaluationLabelWithNumber,
  workExperience: AutoEvaluationLabelWithNumber,
  educations: AutoEvaluationLabel[],
  certifications: AutoEvaluationLabel[],
  skills: AutoEvaluationLabel[],
  school: AutoEvaluationLabel[],
  keywords: AutoEvaluationLabel[],
}

export type JobOpeningDetail = {
  creationDateTime: number | string,
  organizationId: string,
  jobOpeningElements: jobOpeningTypes[],
  jobRequisition?: JobRequisition,
  jobDescription?: JobDescription,
  autoSuitability?: AutoEvaluation,
  autoOutstanding?: AutoEvaluation,
  autoAssessment?: AutoAssessment,
  questionAdded?: boolean
}

export type AutoAssessment = {
  id: string,
  jobOpeningId: string,
  assessment: Assessment,
  skills?: JobOpeningSkill[],
  status?: string,
  emailTemplate?: string,
  emailJsonTemplate?: string,
}

export type JobApplicant = {
  jobOpeningId: string;
  organizationId: string;
  baseFilename?: string;
  filename?: string;
  createdAt: number | string;
  documentType?: 'Resume' | 'Cover Letter';
  fileHash?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: string;
  gender?: string | null | undefined;
  applicantStatus: string;
  errorMessage?: string;
  transcript?: string;
  completed: boolean;
  profileImageFilename?: string;
  base64Encoded?: string;
  contactInfo?: ContactInfo;
  educations?: Education[];
  workExperience?: WorkExperience[];
  skills?: string[];
  certifications?: string[];
  id?: string;
  pdfUrl?: string;
  highlightSkills?: HighlightSkill[][];
  highlightWorkExperience?: any[];
}

export type ContactInfo = {
    phone: string;
    address: Address;
}

export type Address = {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

export type Education = {
    degree: string;
    fieldOfStudy: string;
    university: string;
    graduationYear: number;
}

export type WorkExperience = {
    jobTitle: string;
    company: string;
    startDate: number;
    endDate?: number;
    description: string;
}

export type HighlightSkill = {
    value: string;
    type: string;
}

export type JobApplicantSearchRequest = {
    additionalSkills: string[],
    jobTitle: string[],
    keywords: string[],
    gender: string[],
    age: JobApplicantAge,
    applicantStatus: string[] | null,
};

export type JobApplicantCSV = {
    additionalSkills: string[],
    jobTitle: string[],
    keywords: string[],
    gender: string[],
    age: JobApplicantAge,
    applicantStatus: string[] | null,
    skills: JobSkills;
    experience: JobExperience;
};

export type JobSkills = {
    min: number;
    max: number;
};

export type JobExperience = {
    min: number;
    max: number;
};

export type JobApplicantsStatusRequest = {
    jobApplicantIds: string[],
    applicantStatus: string,
}

export type JobApplicantEditRequest = {
    firstName?: string,
    lastName?: string,
    email?: string,
    age?: number | null,
    skills?: string[],
    gender?: string,
    applicantStatus: string,
};

export type JobApplicantAge = {
  minAge: number,
  maxAge: number,
  includeUnknown: boolean,
};

export type Applicant = {
  userId: string,
  value: number[],
  symbol: string,
  initials?: string,
  label?: Label,
  name: string,
  skills: string[],
  firstName: string | null,
  lastName: string | null,
  age: number | null,
  gender: string | null,
}

export type Label = {
  show: boolean,
  formatter: string,
  color: string,
  fontWeight: string
}

export type TabData = {
  tabId: number,
  candidate?: Applicant,
}

export type PieItem = {
    value: number,
    name: string,
}

export type ApplicantResponse = {
    skillCount: number,
    experience: number,
    keywordCount: number,
    skills: string[],
    keyword: string[],
    id: string,
    firstName: string | null,
    lastName: string | null,
    age: number | null,
    gender: string | null,
    profilePic: string | null,
    base64Encoded: string | null,
};

export type Coordinate = [number, number];

export type DataPoint = {
  name: string;
  xAxis: number;
  yAxis: number;
  label: object;
  emphasis: {
    label: object;
  };
  coord: Coordinate[];
}

export type SegmentType = {
  id?: number;
  name?: string;
  xAxis?: number;
  yAxis?: number;
  itemStyle?: {color: string | undefined}
};

export type JobOpeningRequest = {
  jobTitle: string,
  jobReferenceNumber: string,
  hiringManager: string,
  internalRecruiter: string,
  sort: sortJobOpening
}

export type sortJobOpening = {
  expiryDate?: 'asc' | 'desc',
  creationDate?: 'asc' | 'desc',
  jobTitle?: 'asc' | 'desc',
}

export type JobOpeningSkill = {
  skill: string,
  level: string,
  numberOfQuestions: number,
  points: number,
  time: number,
  questionType: string,
  temp?: boolean
}

export type JobOpeningList = {
  id: string,
  jobTitle?: string,
  hiringManager?: string[],
  internalRecruiter?: string[],
  creationDate: number,
  expiryDate?: number,
  applicants: number,
  jobReference?: string
}

export type JobPostingData = {
    location: string;
    jobTitle: string;
    department: string;
    workType: string;
    organizationId: string;
    htmlTemplate: string;
    jsonTemplate: string;
    startDate: number;
    endDate: number;
    active: boolean;
    id: string;
};

export type  FilterState = {
    jobTitle: string[];
    location: string[];
    workType: string[];
    department: string[];
}

export type CandidateJobApplicantsData = {
  email: string,
  applicantStatus: string[],
  name: string,
  assessmentStatus: string[],
}
