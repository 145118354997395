import React, { useState } from 'react';

import RichTextEditor from '../../../../../../ui/RichTextEditor';
import defaultEmailObjectTemplate from './email_template.json';

const defaultJsonTemplate = JSON.stringify(defaultEmailObjectTemplate);

const InvitationTemplateTab = () => {
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [variables, setVariables] = useState<{ name: string, variable: string}[]>([
    { name: 'Applicant Name', variable: 'applicant_name' },
    { name: 'Company Name', variable: '' },
    { name: 'Job Title Name', variable: 'job_title_name' },
    { name: 'End of Assessment', variable: 'end_of_assessment' },
    { name: 'Support Email', variable: '' },
    { name: 'Support Phone', variable: '' },
    { name: 'Company Logo', variable: '' },
    { name: 'Assessment Link', variable: 'assessment_link' },
    { name: 'Unset Link', variable: '' },
  ]);

  const [initialEditorState, setInitialEditorState] = useState<any>(null);
  const [isImported, setIsImported] = useState<boolean>(false);

  const editedTemplate = defaultJsonTemplate;

  const saveTemplate = async (emailTemplate: string, emailJsonTemplate: string) => {
    console.log('test');
  };

  return (
    <div className='relative flex flex-col gap-4 h-full p-10'>
      <div className='flex-grow'>
        <div>
          <RichTextEditor
              jsonStringTemplate={editedTemplate}
              saveTemplate={saveTemplate}
              onEditorReady={setEditorInstance}
              validVariables={variables}
          />
        </div>
      </div>
    </div>
  );
};

export default InvitationTemplateTab;
