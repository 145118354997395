import React from 'react';
import overviewBinocularsSVG from '../../../../../../../assets/images/graphics/overview-binoculars.svg';
import resultPlayback from '../../../../../../../assets/images/graphics/result-playback.svg';
import resultQNA from '../../../../../../../assets/images/graphics/result-qna.svg';
import resultScore from '../../../../../../../assets/images/graphics/result-score.svg';

interface TabButtonProps {
    icon: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
    tooltip: string;
}

const TabButton: React.FC<TabButtonProps> = ({ icon, isActive, onClick, tooltip }) => (
    <button
        onClick={onClick}
        className={`w-full p-3 mb-2 rounded-lg transition-all duration-200 relative group`}
    >
        <div
            className={`w-10 h-10 mx-auto transition-all duration-200
                        ${isActive ? 'text-[#375CAA]' : ''}`}
        >
            {icon}
        </div>
        {/* Tooltip on the left side with green text on a white background */}
        <div className='absolute right-full top-1/2 transform -translate-y-1/2 mr-2 opacity-0 group-hover:opacity-100 text-xs text-white bg-black p-2 rounded-md transition-opacity duration-300 delay-1500'>
            {tooltip}
        </div>
    </button>
);

interface ResultTabsProps {
    activeTab: number;
    setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const ResultTabs: React.FC<ResultTabsProps> = ({ activeTab, setActiveTab }) => {
    const icons = [
        <img
            key='overview'
            src={overviewBinocularsSVG}
            alt='Overview'
            className={`w-10 h-10 mx-auto transition-all duration-200
                        ${activeTab === 0
                ? 'filter brightness-0 invert-[20%] sepia-[80%] hue-rotate-[200deg] saturate-[200%]'
                : 'filter brightness-0 invert'}`}
        />,
        <img
            key='score'
            src={resultScore}
            alt='resultScore'
            className={`w-10 h-10 mx-auto transition-all duration-200
                        ${activeTab === 1
                ? 'filter brightness-0 invert-[20%] sepia-[80%] hue-rotate-[200deg] saturate-[200%]'
                : 'filter brightness-0 invert'}`}
        />,
        <img
            key='qna'
            src={resultQNA}
            alt='qna'
            className={`w-10 h-10 mx-auto transition-all duration-200
                        ${activeTab === 2
                ? 'filter brightness-0 invert-[20%] sepia-[80%] hue-rotate-[200deg] saturate-[200%]'
                : 'filter brightness-0 invert'}`}
        />,
        <img
            key='playback'
            src={resultPlayback}
            alt='playback'
            className={`w-10 h-10 mx-auto transition-all duration-200
                        ${activeTab === 3
                ? 'filter brightness-0 invert-[20%] sepia-[80%] hue-rotate-[200deg] saturate-[200%]'
                : 'filter brightness-0 invert'}`}
        />,
    ];

    const tooltips = ['Overview', 'Compare Score', 'Compare Q&A', 'Playback'];

    return (
        <div className='h-full w-full bg-[#375CAA] rounded-lg shadow-lg p-4 flex flex-col justify-between'>
            <div className='space-y-1'>
                {icons.map((icon, index) => (
                    <TabButton
                        key={index}
                        icon={icon}
                        isActive={activeTab === index}
                        onClick={() => setActiveTab(index)}
                        tooltip={tooltips[index]}
                    />
                ))}
            </div>
            {activeTab === 0 && (
                <button
                    onClick={() => console.log('Next Page clicked')}
                    className='w-full mt-4 p-3 rounded-lg text-white font-semibold hover:font-black transition-all duration-200 text-sm'
                >
                    Next Page
                </button>
            )}
        </div>
    );
};

export default ResultTabs;
