import React, { useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { MultiSelectListItem } from '../../../../../../ui/data_source_filter/MultiSelectListItem';
import { ToggleInput } from '../../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';
import MultiSelectCalendar from '../../ui/MulitSelectCalendar';
import TimeIntervalTable from '../../ui/TimeIntervalTable';

const Configurations = () => {
  const [statusInput, setStatusInput] = useState<SelectOptions[]>([]);
  const [timeSlotInput, setTimeSlotInput] = useState<{ value: string; label: string }>({
    value: '30',
    label: '30',
  });
  const [isHostToggleEnabled, setIsHostToggleEnabled] = useState<boolean>(true);
  const [isRecordEnabled, setRecordEnabled] = useState<boolean>(true);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);

  const statusList = [
    { value: 'New', label: 'New' },
    { value: 'Suitable', label: 'Suitable' },
    { value: 'Auto-Assessment', label: 'Auto Assess' },
    { value: 'Outstanding', label: 'Outstanding' },
    { value: 'Shortlisted', label: 'ShortList' },
    { value: 'Unsuccessful-Closed', label: 'Unsuccessful' },
  ];

  const recordList = [
    { value: 'Transcript', label: 'Transcript' },
    { value: 'Transcript and Auto-Score', label: 'Transcript and Auto-Score' },
  ];

  const timeSlotList = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '60', label: '60' },
    { value: '90', label: '90' },
    { value: '120', label: '120' },
  ];

  const handleApplicationStatus = (selectedValues: MultiValue<SelectOptions>) => {
    setStatusInput(selectedValues as SelectOptions[]);
  };

  const handleSelectChange = (action: any) => {
    setTimeSlotInput(action); // Update the timeSlotInput state when selection changes
  };

  const handleDateSelection = (dates: Date[]) => {
    console.log('Selected dates:', dates);
  };

  const handleHostToggle = () => {
    setIsHostToggleEnabled((prevState) => !prevState);
  };

  const handleRecordToggle = () => {
    setRecordEnabled((prevState) => !prevState);
  };

  const selectedTimeSlot =  Number(timeSlotInput?.value || 30);

  return (
    <div className='flex flex-col md:flex-row gap-6 px-4'>
      { /* Left Column */ }
      <div className='w-full md:w-1/3 px-4'>
        <div className='mb-6'>
          <SecondaryTypography.XSmall className='text-gray-500' fontWeight='semi-bold'>
            Add Panelist
          </SecondaryTypography.XSmall>
          <Select
              className='w-full max-w-[400px]'
              placeholder='Mulit-Select Employees'
              name='assessmentType'
              options={statusList}
              onChange={handleApplicationStatus}
              isClearable={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option: MultiSelectListItem }}
              value={statusInput}
              isMulti
          />
        </div>

        <div className='mb-6'>
          <SecondaryTypography.XSmall fontWeight='semi-bold'>
            Select Host
          </SecondaryTypography.XSmall>
          <div className='flex items-center'>
            <ToggleInput
                toggled={isHostToggleEnabled}
                handleToggle={handleHostToggle}
                name='Host Toggle'
                informationText='All Panelist'
            />
            { !isHostToggleEnabled && (
              <Select
                  className='ml-4'
                  placeholder='Multi-Select Hosts'
                  name='assessmentType'
                  options={statusList}
                  onChange={handleApplicationStatus}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: MultiSelectListItem }}
                  value={statusInput}
                  isMulti
              />
            ) }
          </div>
        </div>

        <div>
          <SecondaryTypography.XSmall fontWeight='semi-bold'>
            Recording Options
          </SecondaryTypography.XSmall>
          <div className='flex'>
            <ToggleInput
                toggled={isRecordEnabled}
                handleToggle={handleRecordToggle}
                name='Recording Options'
                informationText='Recording ON/OFF'
            />
            { isRecordEnabled && (
              <Select
                  placeholder='Select Record Options'
                  name='assessmentType'
                  options={recordList}
                  onChange={handleApplicationStatus}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ Option: MultiSelectListItem }}
                  value={statusInput}
                  isMulti
              />
            ) }
          </div>
        </div>
      </div>

      { /* Right Column */ }
      <div className='w-full md:w-2/3 px-4'>
        <div className='mb-6'>
          <SecondaryTypography.XSmall className='text-gray-500' fontWeight='semi-bold'>
            Interview Duration
          </SecondaryTypography.XSmall>

          <div className='relative'>
            <Select
                className='w-full max-w-[40vh] mb-2'
                placeholder='Select Timeslot'
                name='assessmentName'
                options={timeSlotList}
                onChange={handleSelectChange}
                isClearable={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={timeSlotInput}
            />
            <SecondaryTypography.XSmall className='text-gray-500' fontWeight='semi-bold'>
              Time slot selections
            </SecondaryTypography.XSmall>
            <div className='flex gap-4'>
              <div className='w-full max-w-[40vh]'>
                <MultiSelectCalendar
                    onDateSelect={handleDateSelection}
                    selectedDates={selectedDates}
                    setSelectedDates={setSelectedDates}
                />
              </div>
              <div className='w-full max-w-[60vh]'>
                { selectedDates.length > 0 && (
                  <TimeIntervalTable
                      selectedDates={selectedDates}
                      timeSlot={selectedTimeSlot} // Use the updated timeSlotInput value
                  />
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
