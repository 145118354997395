import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnalyticAllSvg from '../../../../assets/images/graphics/analytic-all.svg';
import AnalyticAssessmentSvg from '../../../../assets/images/graphics/analytic-assessment.svg';
import AnalyticOutstandingSvg from '../../../../assets/images/graphics/analytic-outstanding.svg';
import AnalyticShortListSvg from '../../../../assets/images/graphics/analytic-shortList.svg';
import AnalyticSuitableSvg from '../../../../assets/images/graphics/analytic-suitable.svg';
import AnalyticUnsuccessfulISvg from '../../../../assets/images/graphics/analytic-unsuccessfulI.svg';
import { getJobDescription, twoDJobApplicants } from '../../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../../utils/contexts/JobOpening';
import {
  Applicant,
  ApplicantResponse,
  JobApplicantCSV,
  JobApplicantSearchRequest,
  TabData,
} from '../../../../utils/types/JobOpenings';
import { Tabs } from '../../../assessment/view/tabs/tabs';
import ButtonColumn, { SingleButton } from '../ui/ButtonColumn';
import { CandidateProfile } from './CandidateProfile';
import { TwoDChart } from './TwoDChart';

export const AllApplicantAnalysis = () => {
  const [tabTitles, setTabTitles] = useState<string[]>(['All Applicants']);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedCandidates, setSelectedCandidates] = useState<TabData[]>([]);
  const { jobOpeningElements } = useContext(JobOpeningsContext);
  const [currentTab, setCurrentTab] = useState<string>('All Applicants');
  const [status, setStatus] = useState<string[]>(['New', 'Suitable', 'Outstanding', 'ShortList', 'Unsuccessful', 'Auto Assess']);
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [similarKeyword, setSimilarKeyword] = useState<string>('');
  const [exactKeyword, setExactKeyword] = useState<string>('');
  const [skillsInput, setSkillsInput] = useState<string>('');
  const [jobTitleInput, setJobTitleInput] = useState<string>('');
  const [defaultSkills, setDefaultSkills] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();
  const [view3D, setView3D] = useState<boolean>(false);
  const [skillValue, setSkillValue] = useState<number[]>([0,10]);
  const [experienceValue, setExperienceValue] = useState<number[]>([0,10]);
  const [candidates, setCandidates] = useState<Applicant[]>([]);
  const [minMaxAge, setMinMaxAge] = useState<number[]>([0, 100]);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchObject, setSearchObject] = useState<JobApplicantSearchRequest>({
    additionalSkills: [],
    jobTitle: [jobTitleInput],
    keywords: [],
    gender: [],
    age: {
      minAge: 0,
      maxAge: minMaxAge[1],
      includeUnknown: true,
    },
    applicantStatus: statusValue,
  });

  const [searchCSVObject, setSearchCSVObject] = useState<JobApplicantCSV>({
    additionalSkills: defaultSkills,
    jobTitle: [jobTitleInput],
    keywords: [],
    gender: [],
    age: {
      minAge: 0,
      maxAge: minMaxAge[1],
      includeUnknown: true,
    },
    applicantStatus: statusValue,
    skills: {
      min: 0,
      max: 10,
    },
    experience: {
      min: 0,
      max: 10,
    },
  });

  const fetchJobApplicants = async () => {
    try {
      const response = await twoDJobApplicants(id, searchObject);
      const responseData = await response.json();
      const getInitials = (name: string) => {
        const nameParts = name.split(' ').filter(Boolean);
        const initials = nameParts.map(part => part[0].toUpperCase()).join('');
        return initials[0] + initials[1];
      };
      const transformApplicant = (item: ApplicantResponse): Applicant => {
        return {
          userId: item.id,
          value: [item.skillCount, item.experience, item.keywordCount],
          firstName: item.firstName !== null ? item.firstName : 'undefined',
          lastName: item.lastName !== null ? item.lastName : 'undefined',
          age: item.age,
          gender: item.gender,
          name: item.firstName && item.lastName !== null ? item.firstName + ' ' + item.lastName : 'undefined',
          skills: item.skills,
          symbol: item.base64Encoded ? `image://${item.base64Encoded}` : 'circle',
          label: {
            show: item.base64Encoded ? false : true,
            formatter: getInitials(item.firstName && item.lastName !== null ? item.firstName + ' ' + item.lastName : 'undefined'),
            color: 'yellow',
            fontWeight: 'bold',
          },
        };
      };
      const highestExperienceCount = responseData.data.reduce((max:any, applicant:any) => {
        return Math.max(max, applicant.experience);
      }, 0);

      const highestSkillCount = responseData.data.reduce((max:any, applicant:any) => {
        return Math.max(max, applicant.skillCount);
      }, 0);

      const newExperienceCount = highestExperienceCount > 0 ? highestExperienceCount : 10;
      const newSkillCount = highestSkillCount > 0 ? highestSkillCount : 10;
      setExperienceValue([experienceValue[0], newExperienceCount]);
      setSkillValue([skillValue[0], newSkillCount]);

      const transformedData: Applicant[] = responseData.data.map(transformApplicant);
      setCandidates(transformedData);
    } catch (error) {
      toast.error('Error Loading Job Applicants', { position: 'bottom-center' });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchJobApplicants().then(r => setLoading(false));
  }, [searchObject]);

  useEffect(() => {
    // Update searchObject when skillsInput or jobTitleInput changes
    setSearchObject(prev => ({
      ...prev,
      additionalSkills: skillsInput === '' ? [] : skillsInput.split(':'),
      jobTitle: jobTitleInput === '' ? [] : [jobTitleInput],
    }));
  }, [skillsInput, jobTitleInput]);

  useEffect(() => {
    const elementsExceptFirst = tabTitles.slice(1);
    const filteredSelection = selectedCandidates.filter(item => item.candidate && elementsExceptFirst.includes(item.candidate.name));
    const updatedSelection = filteredSelection.map((item, index) => ({
      ...item,
      tabId: index + 1,
    }));
    setSelectedCandidates(updatedSelection);
  }, [activeTab]);

  useEffect(() => {
    const fetchJobDescriptionDetails = async () => {
      try {
        const response = await getJobDescription(id);
        if (response.ok) {
          const responseData = await response.json();

          // Process the fetched data to get the mandatory skills and job title
          const mandatorySkills = responseData.mandatorySkills;
          const mandatorySkillsString = mandatorySkills.join(':');  // Join the skills for input
          const jobTitle = responseData.jobTitle.jobTitle;

          // Only update the state after the API call completes and data is available
          setDefaultSkills(mandatorySkills);  // Set the defaultSkills array
          setJobTitleInput(jobTitle);         // Set the job title input
          setSkillsInput(mandatorySkillsString);
        }
      } catch (error) {
        console.error('Error fetching job description:', error);  // Handle errors appropriately
      }
    };

    fetchJobDescriptionDetails();  // Call the function to fetch data
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case 'All Applicants':
        setStatusValue([]);
        setStatus(['New', 'Suitable', 'Outstanding', 'ShortList', 'Unsuccessful', 'Auto Assess']);
        break;
      case 'Suitable Applicants':
        setStatus(['Suitable']);
        setStatusValue(['Suitable']);
        break;
      case 'Outstanding Applicants':
        setStatus(['Outstanding']);
        setStatusValue(['Outstanding']);
        break;
      case 'Assessment Applicants':
        setStatus(['Auto Assess']);
        setStatusValue(['Auto-Assessment']);
        break;
      case 'Unsuccessful Applicants':
        setStatus(['Unsuccessful']);
        setStatusValue(['Unsuccessful-Closed']);
        break;
      case 'ShortList Applicants':
        setStatus(['ShortList']);
        setStatusValue(['Shortlisted']);
        break;
      default:
        break;
    }
  }, [currentTab]);

  const renderTab = () => {
    if (activeTab > tabTitles.length-1) {
      setActiveTab(activeTab-1);
    }
    if (activeTab === 0) {
      return (
        <TwoDChart
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            setActiveTab={setActiveTab}
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            setStatus={setStatus}
            status={status}
            statusValue={statusValue}
            setSimilarKeyword={setSimilarKeyword}
            similarKeyword={similarKeyword}
            setExactKeyword={setExactKeyword}
            exactKeyword={exactKeyword}
            defaultSkills={defaultSkills}
            skillsInput={skillsInput}
            setSkillsInput={setSkillsInput}
            jobTitleInput={jobTitleInput}
            setDefaultJobTitles={setJobTitleInput}
            view3D={view3D}
            setView3D={setView3D}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            searchCSVObject={searchCSVObject}
            setSearchCSVObject={setSearchCSVObject}
            candidates={candidates}
            setCandidates={selectedCandidates}
            skillValue={skillValue}
            setSkillValue={setSkillValue}
            experienceValue={experienceValue}
            setExperienceValue={setExperienceValue}
            minMaxAge={minMaxAge}
            setMinMaxAge={setMinMaxAge}
        />
      );
    }
    else {
      const tabCandidate = selectedCandidates.find(item => item.tabId === activeTab);
      const candidateValues = tabCandidate?.candidate;
      if (candidateValues) {
        return(
          <CandidateProfile
              selectedCandidate={candidateValues}
          />
        );
      }
    }
  };

  const buttonsList: SingleButton[] = useMemo(() => ([
    {
      label: 'All Applicants',
      disabled: false,
      svg: AnalyticAllSvg,
    },
    {
      label: 'Suitable Applicants',
      disabled: false,
      svg: AnalyticSuitableSvg,
    },
    {
      label: 'Outstanding Applicants',
      disabled: false,
      svg: AnalyticOutstandingSvg,
    },
    {
      label: 'Assessment Applicants',
      disabled: false,
      svg: AnalyticAssessmentSvg,
    },
    {
      label: 'Unsuccessful Applicants',
      disabled: false,
      svg: AnalyticUnsuccessfulISvg,
    },
    {
      label: 'ShortList Applicants',
      disabled: false,
      svg: AnalyticShortListSvg,
    },
  ]), [jobOpeningElements]);

  const handleStatusChange = useCallback((activeLabel: string) => {
    setCurrentTab(activeLabel);
  }, []);

  return (
    <div className='flex'>
      <ButtonColumn buttons={buttonsList} onStatusChange={handleStatusChange} currentTab={currentTab}/>
      <div
          className='w-full min-h-[80vh] bg-primaryWhite100 shadow-[0_16px_30px_rgba(55,92,170,0.15)] rounded-lg box-border'
      >
        <Tabs
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            addClose={true}
        />
        { renderTab() }
      </div>
    </div>
  );
};
