import React, { useState } from 'react';
import { Button } from '../../../../../../ui/button/button';
import RichTextEditor from '../../../../../../ui/RichTextEditor';
import { JobDescription, JobPostingTabData } from '../../../../../../utils/types/JobOpenings';
import defaultEmailObjectTemplate from './email_template.json';

const defaultJsonTemplate = JSON.stringify(defaultEmailObjectTemplate);

type JobPostDetailsProps = {
    jobPostingData: JobPostingTabData;
    jobDescription: JobDescription;
    setJobPostingData: React.Dispatch<React.SetStateAction<JobPostingTabData>>;
    setActiveTab: (value: ((prevState: number) => number) | number) => void;
};

const JobPostDetails = ({
  jobPostingData,
  jobDescription,
  setJobPostingData,
  setActiveTab,
}: JobPostDetailsProps) => {
  const [editorInstance, setEditorInstance] = useState<any>(null);

  const [variables, setVariables] = useState<{ name: string; variable: string }[]>([
    {
      name: 'Responsibility',
      variable: jobDescription.responsibilities
        ? `<ul>${jobDescription.responsibilities
          .map((responsibility) => `<li>${responsibility}</li>`)
          .join('')}</ul>`
        : '',
    },
    {
      name: 'Required Skills',
      variable: jobDescription.mandatorySkills
        ? `<ul>${jobDescription.mandatorySkills
          .map((skill) => `<li>${skill}</li>`)
          .join('')}</ul>`
        : '',
    },
    {
      name: 'Desired Skills',
      variable: jobDescription.desirableSkills
        ? `<ul>${jobDescription.desirableSkills
          .map((skill) => `<li>${skill}</li>`)
          .join('')}</ul>`
        : '',
    },
  ]);

  const [initialEditorState, setInitialEditorState] = useState<any>(null);
  const [isImported, setIsImported] = useState<boolean>(false);

  const insertResponsibilities = () => {
    if (editorInstance && jobDescription.responsibilities && jobDescription.responsibilities.length > 0) {
      const doc = editorInstance.state.doc.toJSON();

      const responsibilitiesIndex = doc.content.findIndex(
        (node: any) =>
          node.type === 'paragraph' &&
              node.content?.[0]?.text === 'Responsibilities' &&
              node.content?.[0]?.marks?.some((mark: any) => mark.type === 'bold'),
      );

      if (responsibilitiesIndex >= 0) {
        let position = 0;
        for (let i = 0; i <= responsibilitiesIndex; i++) {
          const node = editorInstance.state.doc.child(i);
          position += node.nodeSize;
        }

        const bulletList = {
          type: 'bulletList',
          content: jobDescription.responsibilities.map((item) => ({
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: item }] }],
          })),
        };

        editorInstance.chain().focus().insertContentAt(position, bulletList).run();
      }
    }
  };

  const insertRequiredSkills = () => {
    if (editorInstance && jobDescription.mandatorySkills && jobDescription.mandatorySkills.length > 0) {
      const doc = editorInstance.state.doc.toJSON();

      const requiredSkillsIndex = doc.content.findIndex(
        (node: any) =>
          node.type === 'paragraph' &&
              node.content?.[0]?.text === 'Required Skills' &&
              node.content?.[0]?.marks?.some((mark: any) => mark.type === 'bold'),
      );

      if (requiredSkillsIndex >= 0) {
        let position = 0;
        for (let i = 0; i <= requiredSkillsIndex; i++) {
          const node = editorInstance.state.doc.child(i);
          position += node.nodeSize;
        }

        const bulletList = {
          type: 'bulletList',
          content: jobDescription.mandatorySkills.map((item) => ({
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: item }] }],
          })),
        };

        editorInstance.chain().focus().insertContentAt(position, bulletList).run();
      }
    }
  };

  const insertDesiredSkills = () => {
    if (editorInstance && jobDescription.desirableSkills && jobDescription.desirableSkills.length > 0) {
      const doc = editorInstance.state.doc.toJSON();

      const desiredSkillsIndex = doc.content.findIndex(
        (node: any) =>
          node.type === 'paragraph' &&
              node.content?.[0]?.text === 'Desired Skills' &&
              node.content?.[0]?.marks?.some((mark: any) => mark.type === 'bold'),
      );

      if (desiredSkillsIndex >= 0) {
        let position = 0;
        for (let i = 0; i <= desiredSkillsIndex; i++) {
          const node = editorInstance.state.doc.child(i);
          position += node.nodeSize;
        }

        const bulletList = {
          type: 'bulletList',
          content: jobDescription.desirableSkills.map((item) => ({
            type: 'listItem',
            content: [{ type: 'paragraph', content: [{ type: 'text', text: item }] }],
          })),
        };

        editorInstance.chain().focus().insertContentAt(position, bulletList).run();
      }
    }
  };

  const insertAll = () => {
    setInitialEditorState(editorInstance.state.doc.toJSON());
    insertResponsibilities();
    insertRequiredSkills();
    insertDesiredSkills();
    setIsImported(true);
  };

  const undoImports = () => {
    if (editorInstance && initialEditorState) {
      editorInstance.commands.setContent(initialEditorState);
      setIsImported(false);
    }
  };

  const editedTemplate = defaultJsonTemplate;

  const saveTemplate = async (emailTemplate: string, emailJsonTemplate: string) => {
    setJobPostingData({ ...jobPostingData, htmlTemplate: emailTemplate, jsonTemplate: emailJsonTemplate });
    setActiveTab(2);
  };

  return (
    <div className='relative flex flex-col gap-4 h-full p-10'>
      <div className='absolute top-0 right-0'>
        <Button
            onClick={isImported ? undoImports : insertAll}
            variant={isImported ? 'secondary' : 'primary'}
            size='medium'
        >
          { isImported ? 'Undo Imports' : 'Import from JD' } { /* Toggle button text */ }
        </Button>
      </div>
      <div className='flex-grow'>
        <div className='text-gray-800 py-1 text-sm'>Role Details</div>
        <div>
          <RichTextEditor
              jsonStringTemplate={editedTemplate}
              saveTemplate={saveTemplate}
              validVariables={variables}
              value={jobPostingData.htmlTemplate}
              onEditorReady={setEditorInstance}
          />
        </div>
      </div>
    </div>
  );
};

export default JobPostDetails;
