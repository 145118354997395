import React from 'react';
import { Button } from '../button/button';

interface ConfirmationModalProps  {
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal = ({ message, onConfirm, onCancel }: ConfirmationModalProps) => (
  <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
    <div className='bg-white rounded-lg shadow-lg p-6 w-80 text-center'>
      <p className='text-gray-800 text-lg mb-4'>{ message }</p>
      <div className='flex justify-center gap-4'>
        <Button
            variant='danger'
            size='medium'
            onClick={onConfirm}
        >
          Yes
        </Button>
        <Button
            variant='success'
            size='medium'
            onClick={onCancel}
        >
          No
        </Button>
      </div>
    </div>
  </div>
);

export default ConfirmationModal;
