import classNames from 'classnames';
import React, { useState } from 'react';
import ConfirmationModal from '../../../../ui/ConfirmationModal';
import { ChevronDown, ChevronRight, CreateNode, PencilIcon, TrashIcon } from '../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { PartnerEntity } from '../../../../utils/types/admin_types';
import styles from '../../OrganizationSettings/TreeStructure/TreeStructure.module.css';

interface EntityTreeProps {
  entities: PartnerEntity[] | undefined;
  onEditClick: (entity: PartnerEntity) => void;
  onDeleteClick: (entity: PartnerEntity) => void;
  onAddClick: (entity: PartnerEntity) => void;
}

const EntityTree: React.FC<EntityTreeProps> = ({
  entities,
  onEditClick,
  onDeleteClick,
  onAddClick,
}) => {
  const [expandedEntities, setExpandedEntities] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<PartnerEntity | null>(null);

  const handleToggle = (entityId: string) => {
    setExpandedEntities((prevExpanded) =>
      prevExpanded.includes(entityId)
        ? prevExpanded.filter((id) => id !== entityId)
        : [...prevExpanded, entityId],
    );
  };

  const handleDeleteClick = (entity: PartnerEntity) => {
    setEntityToDelete(entity);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (entityToDelete) {
      onDeleteClick(entityToDelete);
      setEntityToDelete(null);
    }
    setShowModal(false);
  };

  const renderEntity = (entity: PartnerEntity) => (
    <div key={entity.id}>
      <div className={classNames(styles.treeRowContainer, 'hover:bg-gray-200')}>
        <div className={styles.expansionIcons} onClick={() => handleToggle(entity.id)}>
          { expandedEntities.includes(entity.id) ? <ChevronDown /> : <ChevronRight /> }
          <SecondaryTypography.XSmall fontWeight='semi-bold'>{ entity.name }</SecondaryTypography.XSmall>
        </div>
        <div className={styles.rightIcons}>
          <PermissionGate requiredPermissions={[Permission.PARTNER_EDIT]}>
            <div onClick={() => onEditClick(entity)}>
              <PencilIcon />
            </div>
          </PermissionGate>
          <PermissionGate requiredPermissions={[Permission.PARTNER_DELETE]}>
            <div onClick={() => handleDeleteClick(entity)}>
              <TrashIcon />
            </div>
          </PermissionGate>
          <PermissionGate requiredPermissions={[Permission.PARTNER_CREATE]}>
            <div onClick={() => onAddClick(entity)}>
              <CreateNode />
            </div>
          </PermissionGate>
        </div>
      </div>
      { expandedEntities.includes(entity.id) && renderSubEntities(entity.subEntities) }
    </div>
  );

  const renderSubEntities = (subEntities: PartnerEntity[] | undefined) =>
    subEntities?.map((subEntity) => (
      <div key={subEntity.id} style={{ marginLeft: '20px' }}>
        { renderEntity(subEntity) }
      </div>
    ));

  return (
    <>
      <div>{ entities?.map(renderEntity) }</div>
      { showModal && (
        <ConfirmationModal
            message='Are you sure you want to delete this entity?'
            onConfirm={confirmDelete}
            onCancel={() => setShowModal(false)}
        />
      ) }
    </>
  );
};

export default EntityTree;
