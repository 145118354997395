import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../../../../../ui/ConfirmationModal';
import { TrashIcon } from '../../../../../../ui/icons/icons';
import { ToggleInput } from '../../../../../../ui/input/toggle_input/toggle_input';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import { deleteAssessment, updateAssessmentStatus } from '../../../../../../utils/api_service/assessment_api';
import { Assessment } from '../../../../../../utils/types/assessment_types';
import styles from './assessment_row.module.css';

const PRACTICE: string = 'practice';
type AssessmentRowProps = {
    assessment: Assessment
    assessmentList: Assessment[]
    setAssessments: (assessmentList: Assessment[]) => void,
}

export const AssessmentRow = ({
  assessment,
  assessmentList,
  setAssessments,
}:AssessmentRowProps) => {
  const history = useHistory();
  const [active, setActive] = useState<boolean>(assessment.isActive);
  const [showModal, setShowModal] = useState<boolean>(false);

  const setActiveStatus = () => {
    if (assessment.assessmentType !== PRACTICE) {
      updateAssessmentStatus(assessment.id, !active).then(response => {
        if (response.ok) {
          setActive(!active);
        }
      });
    }
  };

  const confirmDelete = () => {
    deleteAssessment(assessment.id).then((response) => {
      if (response.ok) {
        const assessmentListCopy = assessmentList.filter((item) => item.id !== assessment.id);
        setAssessments(assessmentListCopy);
      }
    });
  };

  return (
    <>
      <tr
          className={`${styles.tableRow} ${assessment.assessmentType === PRACTICE ? styles.practice : styles.inactive}`}
      >
        <td onClick={() => history.push(`/view-assessment/${assessment.id}`)}>
          <SecondaryTypography.XSmall alignment='center'>
            { assessment.assessmentTitle }
          </SecondaryTypography.XSmall>
        </td>
        <td onClick={() => history.push(`/view-assessment/${assessment.id}`)}>
          <SecondaryTypography.XSmall alignment='center' className={styles.assessmentTypeText}>
            { assessment.assessmentType }
          </SecondaryTypography.XSmall>
        </td>
        <td onClick={() => history.push(`/view-assessment/${assessment.id}`)}>
          <SecondaryTypography.XSmall alignment='center'>{ assessment.user?.email }</SecondaryTypography.XSmall>
        </td>
        <td className={styles.toggleButtonCell}>
          <ToggleInput toggled={active} handleToggle={setActiveStatus} name='isActive' />
          <div
              className={`${styles.deleteIcon} ${
                assessment.assessmentType === PRACTICE ? styles.practiceIcon : styles.notPracticeIcon
              }`}
              onClick={() => setShowModal(true)}
          >
            <TrashIcon />
          </div>
        </td>
      </tr>
      { showModal && (
        <ConfirmationModal
            message='Are you sure you want to delete this assessment?'
            onConfirm={() => {
              confirmDelete();
              setShowModal(false);
            }}
            onCancel={() => setShowModal(false)}
        />
      ) }
    </>
  );
};
