import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import React, { useState } from 'react';
import { Button } from '../../../../../../ui/button/button';

interface MultiSelectCalendarProps {
    onDateSelect?: (selectedDates: Date[]) => void;
    selectedDates: Date[];
    setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>;
}

const MultiSelectCalendar: React.FC<MultiSelectCalendarProps> = ({
  onDateSelect,
  selectedDates,
  setSelectedDates,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);
  const [draggedDates, setDraggedDates] = useState<Date[]>([]);
  const [dragStartDate, setDragStartDate] = useState<Date | null>(null);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);

  const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  const handleDateClick = (date: Date) => {
    if (isDragging) return; // Avoid triggering individual selection during drag

    const alreadySelected = selectedDates.some((d) => isSameDay(d, date));
    let updatedDates;

    if (alreadySelected) {
      // Remove the date if already selected
      updatedDates = selectedDates.filter((d) => !isSameDay(d, date));
    } else {
      // Add the new date and sort
      updatedDates = [...selectedDates, date];
    }

    // Sort dates in ascending order
    updatedDates.sort((a, b) => a.getTime() - b.getTime());
    setSelectedDates(updatedDates);
    onDateSelect?.(updatedDates);
  };

  const handleRightClick = (date: Date, event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default context menu
    setIsDragging(true);
    setDragStartDate(date);
    setDraggedDates([date]);
  };

  const handleDragOver = (date: Date) => {
    if (!isDragging) return;

    setDraggedDates((prev) => {
      if (prev.some((d) => isSameDay(d, date)) || selectedDates.some((d) => isSameDay(d, date))) {
        // Avoid adding duplicates
        return prev;
      }
      return [...prev, date];
    });
  };

  const handleDragEnd = () => {
    if (dragStartDate) {
      // Merge dragged dates with selectedDates, avoiding duplicates
      const uniqueDates = Array.from(
        new Set([...selectedDates, ...draggedDates].map((d) => d.getTime())),
      )
        .map((time) => new Date(time))
        .sort((a, b) => a.getTime() - b.getTime()); // Sort in ascending order

      setSelectedDates(uniqueDates);
      onDateSelect?.(uniqueDates);
    }

    setIsDragging(false);
    setDraggedDates([]);
    setDragStartDate(null);
  };

  const renderDays = () => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return (
      <div className='grid grid-cols-7 text-center font-medium'>
        { daysOfWeek.map((day) => (
          <div key={day} className='py-2'>
            { day }
          </div>
        )) }
      </div>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const dayCopy = new Date(day);
        const isCurrentMonth = isSameMonth(dayCopy, monthStart);
        const isSelected = selectedDates.some((d) => isSameDay(d, dayCopy));
        const isDragged = draggedDates.some((d) => isSameDay(d, dayCopy));

        days.push(
          <div
              key={dayCopy.toISOString()}
              onMouseDown={(event) => isCurrentMonth && event.button === 0 && handleDateClick(dayCopy)}
              onContextMenu={(event) => isCurrentMonth && handleRightClick(dayCopy, event)}
              onMouseEnter={() => isCurrentMonth && handleDragOver(dayCopy)}
              onMouseUp={handleDragEnd}
              className={`p-2 cursor-pointer text-center ${
                isCurrentMonth ? 'text-gray-900' : 'text-gray-400'
              } ${
                isSelected || isDragged
                  ? 'bg-[#29AAE2] text-white rounded-full'
                  : 'hover:bg-gray-200'
              }`}
          >
            { format(dayCopy, 'd') }
          </div>,
        );

        day = addDays(day, 1);
      }
      rows.push(
        <div key={day.toISOString()} className='grid grid-cols-7'>
          { days }
        </div>,
      );
      days = [];
    }
    return rows;
  };

  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  return (
    <div className='max-w-md mx-auto p-4 border rounded-lg shadow-md bg-white'>
      <div className='flex items-center justify-between mb-4'>
        <button
            onClick={handlePrevMonth}
            className='flex items-center justify-center w-10 h-10 rounded-full border border-gray-300 hover:bg-blue-100 hover:border-blue-400 transition-colors shadow-sm'
        >
          <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 text-gray-700'
              viewBox='0 0 20 20'
              fill='currentColor'
          >
            <path
                fillRule='evenodd'
                d='M12.707 14.707a1 1 0 01-1.414 0L7 10.414a1 1 0 010-1.414l4.293-4.293a1 1 0 111.414 1.414L8.414 10l4.293 4.293a1 1 0 010 1.414z'
                clipRule='evenodd'
            />
          </svg>
        </button>
        <h2 className='text-lg font-semibold text-green-500'>
          { format(currentMonth, 'MMMM yyyy') }
        </h2>
        <button
            onClick={handleNextMonth}
            className='flex items-center justify-center w-10 h-10 rounded-full border border-gray-300 hover:bg-blue-100 hover:border-blue-400 transition-colors shadow-sm'
        >
          <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5 text-gray-700'
              viewBox='0 0 20 20'
              fill='currentColor'
          >
            <path
                fillRule='evenodd'
                d='M7.293 14.707a1 1 0 001.414 0L13 10.414a1 1 0 000-1.414L8.707 4.707a1 1 0 10-1.414 1.414L11.586 10l-4.293 4.293a1 1 0 000 1.414z'
                clipRule='evenodd'
            />
          </svg>
        </button>
      </div>

      { selectedDates.length > 0 && (
        <button
            onClick={() => setSelectedDates([])} // Clear all selected dates
            className='px-4 py-2 text-sm text-white bg-red-500 rounded-md hover:bg-red-600 transition-colors'
        >
          Clear All
        </button>
      ) }

      { renderDays() }
      { renderCells() }
      <div className='mt-4 flex items-center justify-between bg-gray-100 p-3 rounded-lg shadow-inner'>
        <div className='flex items-center space-x-2'>
          <span className='text-gray-600 text-sm font-medium'>
            Your Timezone:
          </span>
        </div>
        <span className='text-gray-800 text-sm font-semibold'>{ getTimeZone() }</span>
      </div>
      <div className='mt-2 p-3 bg-gray-200 rounded-lg shadow-inner text-sm'>
        <div className='flex justify-between items-center'>
          <h3 className='font-semibold'>Instructions:</h3>
          <button
              onClick={() => setIsInstructionsOpen((prev) => !prev)}
              className='text-gray-700'
          >
            { /* Arrow icon, change direction based on isInstructionsOpen */ }
            <svg
                xmlns='http://www.w3.org/2000/svg'
                className={`h-5 w-5 transform transition-transform ${
                  isInstructionsOpen ? 'rotate-180' : 'rotate-0'
                }`}
                viewBox='0 0 20 20'
                fill='currentColor'
            >
              <path
                  fillRule='evenodd'
                  d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                  clipRule='evenodd'
              />
            </svg>
          </button>
        </div>
        { isInstructionsOpen && (
          <ul className='list-disc pl-5'>
            <li>Select a date by clicking on it.</li>
            <li>Drag across multiple dates to select a range.</li>
            <li>Right-click to start a drag action and Left-click to end the drag selection.</li>
          </ul>
        ) }
      </div>
    </div>
  );
};

export default MultiSelectCalendar;
