import React from 'react';

type Interview = {
    candidateName: string;
    interviewDate: string; // ISO date format (e.g., "2024-12-13T09:00:00")
};

type UpcomingInterviewsProps = {
    interviews: Interview[];
};

const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

  // Add ordinal suffix to the day
  const day = date.getDate();
  const ordinal =
        day % 10 === 1 && day !== 11
          ? 'st'
          : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
              ? 'rd'
              : 'th';

  return formattedDate.replace(`${day}`, `${day}${ordinal}`);
};

const UpcomingInterviews: React.FC<UpcomingInterviewsProps> = ({ interviews }) => {
  return (
    <div className='p-2 bg-white shadow-sm rounded-md w-52'>
      <h2 className='text-sm font-semibold mb-2 text-green-500'>Upcoming Interviews</h2>
      { interviews.length > 0 ? (
        <ul className='space-y-1'>
          { interviews.map((interview, index) => (
            <li key={index} className='text-xs text-gray-600'>
              <span className='font-medium text-gray-700'>{ interview.candidateName }</span>
              <br />
              <span className='text-gray-500'>{ formatDate(interview.interviewDate) }</span>
            </li>
          )) }
        </ul>
      ) : (
        <p className='text-xs text-gray-500'>No interviews.</p>
      ) }
    </div>
  );
};

export default UpcomingInterviews;
