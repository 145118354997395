import React, { useState } from 'react';

interface ResultCardProps {
    name: string,
    profileImage?: string,
    score: number,
    speed: string,
    onSelect: (checked: boolean) => void,
    isSelected?: boolean
}

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map(part => part[0])
    .join('')
    .toUpperCase();
};

const ResultCard = ({ name, profileImage, score, speed, onSelect, isSelected }: ResultCardProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const hasImage = Boolean(profileImage);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    onSelect?.(event.target.checked);
  };

  return (
    <div className='group relative w-52 overflow-hidden rounded-lg bg-white shadow-sm
                        transition-all duration-300 hover:shadow-md
                        border border-black'>
      { /* Selection overlay */ }
      <div className={`absolute inset-0 bg-blue-50/80 transition-opacity duration-300 
                            ${isChecked ? 'opacity-75' : 'opacity-0'}`}/>
      { /* Checkbox */ }
      <div className='absolute right-2 top-2 z-10'>
        <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
            className='h-4 w-4 cursor-pointer rounded-sm border-2 border-gray-200
                             bg-white text-blue-600 transition-all duration-300
                             checked:bg-blue-500 checked:border-blue-500
                             group-hover:border-blue-500 focus:ring-1
                             focus:ring-blue-500 focus:ring-offset-1'
        />
      </div>

      <div className='flex flex-col items-center p-4'>
        { /* Avatar */ }
        <div className='mb-3 transform transition-transform duration-300 group-hover:scale-105'>
          { hasImage ? (
            <img
                src={profileImage}
                alt={name}
                className='h-16 w-16 rounded-full object-cover ring-2 ring-gray-50'
            />
          ) : (
            <div className='flex h-16 w-16 items-center justify-center rounded-full
                                      bg-gradient-to-br from-blue-50 to-blue-100 ring-1 ring-blue-100'>
              <span className='text-lg font-medium text-blue-600'>
                { getInitials(name) }
              </span>
            </div>
          ) }
        </div>

        { /* Content */ }
        <div className='w-full space-y-3 text-center'>
          <h3 className='text-sm font-medium text-gray-900 truncate px-2'>{ name }</h3>

          <div className='grid grid-cols-2 gap-2 px-1'>
            <div className='rounded-md bg-gray-50 p-2 transition-colors
                                      duration-300 group-hover:bg-white
                                      border border-gray-200'>
              <div className='text-xs font-medium text-gray-500'>
                Score
              </div>
              <div className='text-sm font-semibold text-gray-900'>
                { score }
              </div>
            </div>

            <div className='rounded-md bg-gray-50 p-2 transition-colors
                                      duration-300 group-hover:bg-white
                                      border border-gray-200'>
              <div className='text-xs font-medium text-gray-500'>
                Speed
              </div>
              <div className='text-sm font-semibold text-gray-900'>
                { speed }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
