import { addMinutes, endOfDay, format, isEqual, startOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';

interface TimeIntervalTableProps {
    selectedDates: Date[];
    timeSlot: number;
}

const TimeIntervalTable: React.FC<TimeIntervalTableProps> = ({ selectedDates, timeSlot }) => {
  const [selectedSlots, setSelectedSlots] = useState<{ [key: string]: Date[] }>({});
  const [isDragging, setIsDragging] = useState(false);
  const [draggedSlots, setDraggedSlots] = useState<{ [key: string]: Date[] }>({});

  const generateTimeIntervals = (interval: number) => {
    const effectiveInterval = [60, 90, 120].includes(interval) ? 30 : interval;
    const intervals = [];
    let currentTime = addMinutes(startOfDay(new Date()), 8 * 60); // Start at 8:00 AM
    const endTime = endOfDay(new Date());

    while (currentTime <= endTime) {
      intervals.push(currentTime);
      currentTime = addMinutes(currentTime, effectiveInterval);
    }
    return intervals;
  };

  const getRequiredSlots = (slot: number) => {
    switch (slot) {
      case 60: return 1;  // Only next slot should be unclickable
      case 90: return 2;  // Next two slots should be unclickable
      case 120: return 3; // Next three slots should be unclickable
      default: return 0;
    }
  };

  const removeDuplicatesAndSort = (slots: Date[]): Date[] => {
    const uniqueTimestamps = new Set(slots.map(date => date.getTime()));
    return Array.from(uniqueTimestamps)
      .map(timestamp => new Date(timestamp))
      .sort((a, b) => a.getTime() - b.getTime());
  };

  const isSlotPartOfLargerInterval = (date: Date, time: Date): boolean => {
    const key = format(date, 'yyyy-MM-dd');
    const currentSlots = selectedSlots[key] || [];
    const testTimeslot = currentSlots.filter((_, index) => index % (getRequiredSlots(timeSlot)+1) === 0);
    const timeIndex = timeIntervals.findIndex((t) => isEqual(t, time));
    const requiredSlots = getRequiredSlots(timeSlot);

    // Look back at previous slots to see if this slot should be unclickable
    for (let i = 1; i <= requiredSlots; i++) {
      const previousIndex = timeIndex - i;
      if (previousIndex >= 0) {
        const previousTime = timeIntervals[previousIndex];
        if (testTimeslot.some(slot => isEqual(slot, previousTime))) {
          // Check if we're within the range of slots that should be unclickable
          const remainingSlots = requiredSlots - (timeIndex - previousIndex) + 1;
          if (remainingSlots > 0) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const toggleSlotSelection = (date: Date, time: Date, isDragging = false) => {
    const key = format(date, 'yyyy-MM-dd');
    const currentSlots = isDragging ? draggedSlots[key] || [] : selectedSlots[key] || [];

    const timeIndex = timeIntervals.findIndex((t) => isEqual(t, time));

    // If the slot is part of a larger interval, don't allow selection
    if (isSlotPartOfLargerInterval(date, time)) {
      return;
    }

    // Calculate how many subsequent slots should be selected
    const requiredSlots = Math.ceil(timeSlot / 30);
    const selectedRange = timeIntervals.slice(timeIndex, timeIndex + requiredSlots);

    const isAlreadySelected = selectedRange.every((slot) =>
      currentSlots.some((selected) => isEqual(selected, slot)),
    );

    let updatedSlots = isAlreadySelected
      ? currentSlots.filter(
        (slot) => !selectedRange.some((rangeSlot) => isEqual(rangeSlot, slot)),
      )
      : [...currentSlots, ...selectedRange];

    // Sort the slots

    updatedSlots = removeDuplicatesAndSort(updatedSlots);

    if (isDragging) {
      setDraggedSlots({ ...draggedSlots, [key]: updatedSlots });
    } else {
      setSelectedSlots({ ...selectedSlots, [key]: updatedSlots });
    }
  };

  const handleMouseDown = (date: Date, time: Date, event: React.MouseEvent) => {
    if (event.button === 0) {
      toggleSlotSelection(date, time, false);
    } else if (event.button === 2) {
      event.preventDefault();
      setIsDragging(true);
      toggleSlotSelection(date, time, true);
    }
  };

  const handleMouseEnter = (date: Date, time: Date) => {
    if (!isDragging) return;
    toggleSlotSelection(date, time, true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const mergedSlots = { ...selectedSlots };

    Object.keys(draggedSlots).forEach((key) => {
      const uniqueSlots = Array.from(
        new Set([...(selectedSlots[key] || []), ...(draggedSlots[key] || [])].map((slot) => slot.getTime())),
      ).map((time) => new Date(time));
      mergedSlots[key] = uniqueSlots;
    });

    setSelectedSlots(mergedSlots);
    setDraggedSlots({});
  };

  const timeIntervals = useMemo(() => generateTimeIntervals(timeSlot), [timeSlot]);

  return (
    <div
        className='overflow-hidden border rounded-lg shadow-md p-4 bg-white'
        onMouseUp={handleMouseUp}
        onContextMenu={(e) => e.preventDefault()}
    >
      <div className='overflow-y-auto h-96 scrollbar-hidden'>
        <table className='table-auto min-w-max w-full'>
          <thead>
            <tr>
              { selectedDates.map((date, index) => (
                <th
                    key={index}
                    className='px-4 py-2 text-center text-sm font-medium text-gray-700 whitespace-nowrap'
                >
                  { format(date, 'MMMM dd, yyyy') }
                </th>
              )) }
            </tr>
          </thead>
          <tbody>
            { timeIntervals.map((time, rowIndex) => (
              <tr key={rowIndex} className='even:bg-gray-50'>
                { selectedDates.map((date, colIndex) => {
                  const key = format(date, 'yyyy-MM-dd');
                  const isSelected =
                                    selectedSlots[key]?.some((slot) => isEqual(slot, time)) ||
                                    draggedSlots[key]?.some((slot) => isEqual(slot, time));
                  const isUnclickable = isSlotPartOfLargerInterval(date, time);

                  return (
                    <td
                        key={colIndex}
                        onMouseDown={(e) => handleMouseDown(date, time, e)}
                        onMouseEnter={() => handleMouseEnter(date, time)}
                        className={`px-4 py-2 text-center text-sm whitespace-nowrap border
                                            ${isSelected ? 'bg-[#29AAE2] text-white' : 'text-gray-800'}
                                            ${isUnclickable ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:bg-blue-100'}`}
                    >
                      { format(time, 'h:mm a') }
                    </td>
                  );
                }) }
              </tr>
            )) }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimeIntervalTable;
