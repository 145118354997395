import React, { useState } from 'react';
import ResultCard from './ResultCard';
import ResultsScoreCard from './ResultsScoreCard';
import ResultTabs from './ResultTabs';

interface Person {
    id: string;
    name: string;
    profileImage?: string;
    score: number;
    speed: string;
    totalScore: number;
    dominantEmotion: string;
    informationList: any[];
}

// Sample data
const sampleData: Person[] = [
  { id: '1', name: 'John Doe', score: 95, speed: '72%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },
  { id: '2', name: 'Jane Smith', score: 88, speed: '15%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },
  { id: '3', name: 'Robert Johnson', score: 92, speed: '34%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },
  { id: '4', name: 'Emily Brown', score: 97, speed: '92%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },
  { id: '5', name: 'Michael Wilson', score: 85, speed: '60%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },
  { id: '6', name: 'Sarah Davis', score: 91, speed: '85%', totalScore: 65, dominantEmotion: 'nervouse', informationList: [30, 50, 'test'] },

];

const sampleData1 = [
  {
    id: '1',
    name: 'John Doe',
    score: 95,
    speed: '72%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 30, 'Subskill 2': 50, total: 80 },
      'Question 2': { 'Subskill 1': 40, 'Subskill 2': 60, total: 100 },
      'Question 3': { 'Subskill 1': 50, 'Subskill 2': 70, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I prefer working independently.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is adaptability.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by staying calm and focused.' },
    },
  },
  {
    id: '2',
    name: 'Jane Smith',
    score: 88,
    speed: '15%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 35, 'Subskill 2': 45, total: 80 },
      'Question 2': { 'Subskill 1': 45, 'Subskill 2': 55, total: 100 },
      'Question 3': { 'Subskill 1': 60, 'Subskill 2': 80, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I enjoy collaborating with teams.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is attention to detail.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by taking a step back and reassessing.' },
    },
  },
  {
    id: '3',
    name: 'Robert Johnson',
    score: 92,
    speed: '34%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 40, 'Subskill 2': 50, total: 90 },
      'Question 2': { 'Subskill 1': 50, 'Subskill 2': 60, total: 100 },
      'Question 3': { 'Subskill 1': 55, 'Subskill 2': 75, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I am flexible and can adapt to different working styles.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is problem-solving.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by prioritizing tasks effectively.' },
    },
  },
  {
    id: '4',
    name: 'Emily Brown',
    score: 97,
    speed: '92%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 50, 'Subskill 2': 60, total: 100 },
      'Question 2': { 'Subskill 1': 55, 'Subskill 2': 65, total: 100 },
      'Question 3': { 'Subskill 1': 65, 'Subskill 2': 85, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I prefer structured environments but adapt well to changes.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is leadership.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by maintaining a positive outlook.' },
    },
  },
  {
    id: '5',
    name: 'Michael Wilson',
    score: 85,
    speed: '60%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 25, 'Subskill 2': 35, total: 60 },
      'Question 2': { 'Subskill 1': 40, 'Subskill 2': 50, total: 90 },
      'Question 3': { 'Subskill 1': 60, 'Subskill 2': 70, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I thrive in both independent and team settings.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is creativity.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by focusing on solutions.' },
    },
  },
  {
    id: '6',
    name: 'Sarah Davis',
    score: 91,
    speed: '85%',
    totalScore: 65,
    dominantEmotion: 'nervous',
    informationList: {
      'Question 1': { 'Subskill 1': 40, 'Subskill 2': 50, total: 90 },
      'Question 2': { 'Subskill 1': 50, 'Subskill 2': 60, total: 100 },
      'Question 3': { 'Subskill 1': 55, 'Subskill 2': 75, total: 100 },
    },
    questionAnswer: {
      'Explain responsive web design?': { answer: 'I excel in environments with clear communication.' },
      'What is an anonymous function is JS?': { answer: 'My biggest strength is perseverance.' },
      'What is the difference between HTML tags and elements?': { answer: 'I handle stressful situations by staying organized and proactive.' },
    },
  },
];


const Results: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedCards, setSelectedCards] = useState<Set<string>>(new Set());

  const handleSelect = (id: string, isChecked: boolean) => {
    setSelectedCards(prev => {
      const newSet = new Set(prev);
      if (isChecked) {
        newSet.add(id);
      } else {
        newSet.delete(id);
      }
      return newSet;
    });
    console.log(`Card with id ${id} was ${isChecked ? 'selected' : 'deselected'}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            { sampleData.map(person => (
              <ResultCard
                  key={person.id}
                  name={person.name}
                  profileImage={person.profileImage}
                  score={person.score}
                  speed={person.speed}
                  onSelect={(checked) => handleSelect(person.id, checked)}
                  isSelected={selectedCards.has(person.id)}
              />
            )) }
          </div>
        );
      case 1:
        return (
          <div>
            <ResultsScoreCard data={sampleData1} renderQuestion={false} />
          </div>
        );
      case 2:
        return (
            <div>
              <ResultsScoreCard data={sampleData1} renderQuestion={true} />
            </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='container mx-auto p-4 h-[calc(100vh-4rem)] flex'>
      { /* Cards Section */ }
      <div className='flex-1 overflow-auto'>
        { renderTabContent() }
      </div>

      { /* Navigation Bar */ }
      <div className='w-24 shrink-0'>
        <ResultTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );

};

export default Results;
