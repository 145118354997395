import React, { useRef, useState } from 'react';
import { Button } from '../../../../../../ui/button/button';

interface VideoData {
    title: string;
    description: string;
    videoUrl: string | null;
}

interface VideoMessageDetailsProps {
    setActiveTab: (value: (((prevState: number) => number) | number)) => void
}

const VideoMessageDetails = ({ setActiveTab }: VideoMessageDetailsProps) => {
  const [videoData, setVideoData] = useState<VideoData>({ title: '', description: '', videoUrl: null });
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunks: Blob[] = [];

  const startRecording = async () => {
    try {
      const constraints = {
        video: {
          width: { ideal: 640 },
          height: { ideal: 480 },
        },
        audio: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      }

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        recordedChunks.push(event.data);
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunks, { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setVideoData((prev) => ({ ...prev, videoUrl: url }));

        stream.getTracks().forEach((track) => track.stop());

        if (videoRef.current) {
          videoRef.current.srcObject = null;
          videoRef.current.src = url;
          videoRef.current.play();
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setVideoData((prev) => ({ ...prev, videoUrl: url }));

      if (videoRef.current) {
        videoRef.current.srcObject = null;
        videoRef.current.src = url;
        videoRef.current.play();
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setVideoData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (!videoData.title || !videoData.description || !videoData.videoUrl) {
      alert('Please complete all fields and provide a video before saving.');
      return;
    }

    // Logic to save data, such as sending it to an API
    console.log('Saving Video Data:', videoData);
    alert('Video data saved successfully!');
  };

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-100 py-10'>
      <div
          className='p-8 max-w-2xl w-full bg-white rounded-2xl shadow-lg border border-gray-200 transition-transform transform '>
        <h1 className='text-4xl font-bold mb-8 text-center text-gray-800'>Video Recorder & Uploader</h1>

        <div className='mb-6'>
          <label className='block text-lg font-medium text-gray-700 mb-2'>Title</label>
          <input
              type='text'
              name='title'
              value={videoData.title}
              onChange={handleInputChange}
              placeholder='Enter video title'
              className='w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300'
          />
        </div>

        <div className='mb-6'>
          <label className='block text-lg font-medium text-gray-700 mb-2'>Description</label>
          <textarea
              name='description'
              value={videoData.description}
              onChange={handleInputChange}
              placeholder='Enter video description'
              className='w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300'
              rows={4}
          />
        </div>

        <div className='flex items-center justify-between mb-8'>
          <button
              onClick={isRecording ? stopRecording : startRecording}
              className={`flex-1 mr-2 px-6 py-3 font-semibold text-white rounded-lg shadow-md transition-colors duration-300 ${
                isRecording ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'
              } focus:outline-none`}
          >
            { isRecording ? 'Stop Recording' : 'Start Recording' }
          </button>

          <label
              htmlFor='file-upload'
              className='flex-1 cursor-pointer px-6 py-3 font-semibold text-white bg-teal-600 hover:bg-teal-700 rounded-lg shadow-md transition duration-300 text-center'
          >
            Upload Video
            <input
                id='file-upload'
                type='file'
                accept='video/*'
                onChange={handleUpload}
                className='hidden'
            />
          </label>
        </div>

        <p className='text-sm text-gray-600 mb-4 text-center'>
          Allowed formats: <strong>MP4, MOV, AVI, WMV, AVCHD</strong>
        </p>

        <div className='rounded-lg overflow-hidden border border-gray-300 shadow-lg mb-4'>
          <video ref={videoRef} controls={!isRecording} width={640} height={480} className='w-full'>
            Your browser does not support the video tag.
          </video>
        </div>

        <div className='mt-8 flex justify-center items-center'>
          <Button
              onClick={() => {setActiveTab(3);}}
              variant={'primary'}
              size={'medium'}
          >
            Save and Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoMessageDetails;
