import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AddQuestionModal } from '../../../../../../ui/add_question_modal/add_question_modal';
import { Modal } from '../../../../../../ui/modal/modal';
import { QuestionSearchBar } from '../../../../../../ui/question_search_bar/question_search_bar';
import { PrimaryTypography, SecondaryTypography } from '../../../../../../ui/typography/typography';
import { Permission } from '../../../../../../utils/helpers/permissions';
import PermissionGate from '../../../../../../utils/hooks/PermissionGate';
import { RootState } from '../../../../../../utils/redux_store/store';
import { Question, QuestionInfoTotalCount, QuestionSearch } from '../../../../../../utils/types/assessment_types';
import { SelectOptions } from '../../../../../../utils/types/react_select_types';
import styles
  from '../../../../../assessment/view/view_assessment/assessment_result_list/assessment_result_list.module.css';

const Questions = () => {
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [searchObject, setSearchObject] = useState<QuestionSearch>({
    questionTitle: '',
    skillId: '',
    levelId: '',
    subSkill1Id: '',
    subSkill2Id: '',
    subSkill3Id: '',
    jobTitle: '',
    assessmentId: '',
  });
  const [allCollapsibleOpen, setAllCollapsibleOpen] = useState<boolean>(false);
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState<boolean>(false);
  const skills = useSelector((state: RootState) => state.skills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);
  const levelList = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);

  const setFilterOptions = (event: any) => {
    setSearchObject({
      ...searchObject,
      [event.target.name]: event.target.value,
    });
  };

  const toggleSelected = (event: any) => {
    if (event.target.checked) {
      setSearchObject({
        ...searchObject,
        [event.target.name]: '',
      });
    }
  };

  const toggleAddQuestionModal = () => {
    setAddQuestionModalOpen(true);
  };

  const toggleAllCollapsible = () => {
    setAllCollapsibleOpen(!allCollapsibleOpen);
  };

  const handleChange = (question: Question) => {
    console.log('test');
  };
  const [totalCount, setTotalCount] = useState<QuestionInfoTotalCount>({
    totalPoints: 0,
    totalTime: '00:00',
  });

  const addNewQuestion = (question: Question) => {
    console.log('test');
  };

  return (
    <>
      <PermissionGate
          requiredPermissions={[Permission.ASSESSMENTS_EDIT]}
      >
        <QuestionSearchBar
            onChange={handleChange}
            setFilterOptions={setFilterOptions}
            toggleSelected={toggleSelected}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            toggleAddQuestionModal={toggleAddQuestionModal}
            allCollapsibleState={allCollapsibleOpen}
            toggleAllCollapsibleState={toggleAllCollapsible}
            skillList={skillList}
            levelList={levelList.levelList}
            subSkillList={subSkills.subSkillsList}
            jobTitleList={jobTitles.jobTitlesList}
        />
      </PermissionGate>
      <>
        <PrimaryTypography.TwoXLarge
            className='mt-5 mb-2 text-[#29AAE2]'
            fontWeight='bold'
        >
          List&nbsp;of&nbsp;questions
        </PrimaryTypography.TwoXLarge>
        { questionList.length > 0 ?
          <Questions/> :
          <div
              className='flex justify-center items-center h-screen max-h-[20vh]'
          >
            <SecondaryTypography.Small
                fontWeight='bold'
                keepDefaultMargins={true}
                textCase='uppercase'
            >
              There are currently no questions in this assessment. please add questions from the section above.
            </SecondaryTypography.Small>
          </div>
        }
        <div
            className={styles.questionListSummary}
        >

        </div>
      </>
      <Modal
          visible={addQuestionModalOpen}
          modalContent={
            <AddQuestionModal
                addNewQuestion={addNewQuestion}
                skillList={skillList || []}
                levelList={levelList.levelList || []}
                jobTitleList={jobTitles.jobTitlesList || []}
                subSkillList={subSkills.subSkillsList}
                setSkillList={setSkillList}
            />
          }
          toggle={() => setAddQuestionModalOpen(false)}
      />
    </>
  );
};

export default Questions;
