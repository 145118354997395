import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../../../ui/modal/modal';
import { editJobOpening, getJobOpening } from '../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../utils/contexts/JobOpening';
import { jobOpeningDetailsTabs, jobOpeningPages, jobOpeningTypes } from '../../../utils/types/JobOpenings';
import styles from '../../assessment/create/create_assessment.module.css';
import { AllApplicantAnalysis } from './ApplicantAnalysis/AllApplicantAnalysis';
import Interviews from './Interviews';
import JobOpeningDetails from './JobOpeningDetails';
import StatusMessage from './JobOpeningDetails/StatusMessage';
import { ChooseRecruitmentTypeModal } from './ModalContent/ChooseRecruitementType';
import Chevron from './ui/Chevron';
import LoadingSpinner from './ui/LoaderSpinner';

interface Step {
  label: jobOpeningPages;
  status: 'active' | 'non-active' | 'disabled';
}
const CompleteMappings: Record<jobOpeningPages, jobOpeningDetailsTabs[]> = {
  'Job Opening Details': [],
  'Job Posting': ['Job Requisition', 'Job Description'],
  'Applicant Analysis': ['Job Requisition', 'Job Description', 'CV Ingestion'],
  'Interviews': [],
  'Analytics': [],
};

const MappingJobOpeningElement: Record<jobOpeningPages, jobOpeningTypes> = {
  'Job Opening Details': 'Job Opening Details',
  'Job Posting': 'Job Opening Details',
  'Applicant Analysis': 'Applicant Analysis',
  'Interviews': 'Interviews',
  'Analytics': 'Job Opening Details',
};

export const JobOpeningsCreate = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [modalState, setModalState] = useState<boolean>(!id || id.trim() === '');
  const [currentPage, setCurrentPage] = useState<jobOpeningPages>('Job Opening Details');
  const [jobOpeningLoading, setJobOpeningLoading] = useState<boolean>(false);
  const [jobOpeningElements, setJobOpeningElements] = useState<jobOpeningTypes[]>([]);
  const [completedJobOpeningDetails, setCompletedJobOpeningDetails] = useState<jobOpeningDetailsTabs[]>([]);
  const [pageStatus, setPageStatus] = useState<'enabled' | 'disabled' | 'requiredCompletion' | 'loading'>('loading');
  const [requiredTabs, setRequiredTabs] = useState<string[]>([]);

  useEffect(() => {
    const requiredElements = MappingJobOpeningElement[currentPage];

    if (!jobOpeningElements.includes(requiredElements)) {
      setPageStatus('disabled');
    } else {
      const requiredCompletedTab = CompleteMappings[currentPage];

      const incompleteTabs = requiredCompletedTab.filter((tab) => !completedJobOpeningDetails.includes(tab));
      const allRequiredCompleted = incompleteTabs.length === 0;

      setRequiredTabs(incompleteTabs);
      if (!allRequiredCompleted) {
        setPageStatus('requiredCompletion');
      } else {
        setPageStatus('enabled');
      }
    }
  }, [currentPage, jobOpeningElements, completedJobOpeningDetails]);

  useEffect(() => {
    setJobOpeningLoading(true);
    if (id) {
      getJobOpening(id)
        .then(response => {
          if (!response.ok) {
            toast.error('Error Getting JobOpening', { position: 'bottom-center' });
          }
          return response.json();
        })
        .then(data => {
          setJobOpeningElements(data.jobOpening.jobOpeningElements);
          if (data.CVIngestion) {
            setCompletedJobOpeningDetails([...completedJobOpeningDetails, 'CV Ingestion']);
          }
        })
        .catch(() => {
          toast.error('Error Getting JobOpening', { position: 'bottom-center' });
        });
    } else {
      setJobOpeningElements([]);
      setModalState(true);
    }
    setJobOpeningLoading(false);
  }, [id]);

  const enableElement = async () => {
    try {
      let elements = Array.from(new Set([...jobOpeningElements, MappingJobOpeningElement[currentPage] as jobOpeningTypes]));
      const response = await editJobOpening(elements, id);
      if (response.ok) {
        setJobOpeningElements(elements);
      } else {
        toast.error('Error Updating Job Opening', { position: 'bottom-center' });
      }
    } catch {
      toast.error('Error Updating Job Opening', { position: 'bottom-center' });
    }
  };

  const steps: Step[] = useMemo(() => {
    const initialSteps : Step[] = [
      { label: 'Job Opening Details', status: 'disabled' },
      { label: 'Job Posting', status: 'disabled' },
      { label: 'Applicant Analysis', status: 'disabled' },
      { label: 'Interviews', status: 'disabled' },
      { label: 'Analytics', status: 'non-active' },
    ];

    return initialSteps.map(step => ({
      ...step,
      status: step.label === currentPage ? 'active' : (jobOpeningElements.includes(step.label) ? 'non-active' : 'disabled'),
    }));
  }, [currentPage, jobOpeningElements]);

  const jobOpeningRenderMap: Record<jobOpeningPages, React.ReactNode> = {
    'Job Opening Details': <JobOpeningDetails />,
    'Job Posting': <></>,
    'Applicant Analysis': <AllApplicantAnalysis />,
    'Interviews': <Interviews />,
    'Analytics': <></>,
  };

  const handleModalToggle = () => {
    setModalState(false);
    history.push('/job-openings-list');
  };

  const renderFormPage = () => {
    if (jobOpeningLoading || pageStatus === 'loading') {
      return <LoadingSpinner />;
    }
    if (pageStatus === 'enabled' && currentPage !== null) {
      return jobOpeningRenderMap[currentPage];
    } else {
      return <StatusMessage status={pageStatus} requiredTabs={requiredTabs} enabledElement={enableElement}/>;
    }
  };

  const handlePageChange = (page: jobOpeningPages) => {
    setCurrentPage(page);
    setPageStatus('disabled');
  };

  return (
    <JobOpeningsContext.Provider
        value={{
          jobOpeningElements: jobOpeningElements,
          setJobOpeningElements: setJobOpeningElements,
          completedJobOpeningDetails: completedJobOpeningDetails,
          setCompletedJobOpeningDetails: setCompletedJobOpeningDetails,
        }}
    >
      <div>
        <div
            className='w-full min-h-screen p-4'
        >
          { !modalState && (
            <>
              <Chevron steps={steps} setCurrentPage={handlePageChange}/>
              <div className='flex py-5'>
                <div className='flex w-4/5 items-center gap-5'>
                </div>
                <div className='w-1/5 border-2 flex border-gray justify-center items-center rounded-lg'>
                  <h5 className='text-gray-500 font-bold'>Status</h5>
                </div>
              </div>
              { renderFormPage() }
            </>
          ) }
          <Modal
              visible={modalState}
              modalContent={
                <ChooseRecruitmentTypeModal closeModal={() => setModalState(false)}/>
              }
              toggleable={true}
              customClass={styles.modalDimensions}
              toggle={handleModalToggle}
          />
        </div>
      </div>
    </JobOpeningsContext.Provider>
  );
};
