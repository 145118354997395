import React, { useMemo, useState } from 'react';
import autoOutstandingSvg from '../../../../assets/images/graphics/auto-outstanding.svg';
import autoSuitabilitySvg from '../../../../assets/images/graphics/auto-suitability.svg';
import jobRequisitionSvg from '../../../../assets/images/graphics/job-requisition.svg';
import { InterviewTabs } from '../../../../utils/types/JobOpenings';
import ButtonColumn, { SingleButton } from '../ui/ButtonColumn';
import RoundOne from './RoundOne';

const Interviews = () => {

  const [currentTab, setCurrentTab] = useState<InterviewTabs>('Round 1');

  const buttonsList: SingleButton[] = useMemo(() => ([
    {
      label: 'Round 1',
      disabled: false,
      svg: jobRequisitionSvg,
    },
    {
      label: 'Offer',
      disabled: false,
      svg: autoSuitabilitySvg,
    },
    {
      label: 'Add Round',
      disabled: false,
      svg: autoOutstandingSvg,
    },
  ]), []);

  const renderFormPage = () => {
    if (currentTab !== null) {
      return interviewRenderMap[currentTab];
    }
  };

  const handleStatusChange = (activeLabel: string) => {
    setCurrentTab(activeLabel as InterviewTabs);
  };

  const interviewRenderMap: { [key in string]: React.ReactNode } = {
    'Round 1': <RoundOne/>,
  };

  return (
    <div className='flex gap-6'>
      <ButtonColumn buttons={buttonsList} onStatusChange={handleStatusChange} currentTab={currentTab}/>
      <div
          className={'w-full min-h-[80vh] bg-primaryWhite100 shadow-[0_16px_30px_rgba(55,92,170,0.15)] rounded-lg box-border'}
      >
        { renderFormPage() }
      </div>
    </div>
  );
};

export default Interviews;
