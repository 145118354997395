import React, { useState } from 'react';
import { JobTitle } from '../../../../../utils/types/admin_types';
import { JobDescription, JobPostingTabData } from '../../../../../utils/types/JobOpenings';
import { Tabs } from '../../../../assessment/view/tabs/tabs';
import JobPostDetails from './JobPostDetails';
import JobPostSettings from './JobPostSettings';
import Preview from './Preview';
import VideoMessageDetails from './VideoMessageDetails';

type JobPostingProps = {
    jobTitleList: JobTitle[],
    jobDescription: JobDescription,
}

const JobPosting = ({ jobTitleList, jobDescription }: JobPostingProps) => {
  const [tabTitles, setTabTitles] = useState<string[]>(['Job Post Settings', 'Job Post Details', 'Video Message Details', 'Preview']);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [jobPostingData, setJobPostingData] = useState<JobPostingTabData>(
    {
      jobTitle: jobDescription.jobTitle ? jobDescription.jobTitle as string : '',
      jobDepartment: '',
      location: jobDescription.jobLocation ? jobDescription.jobLocation as string : '',
      htmlTemplate: '',
      jsonTemplate: '',
      workType: 'Office',
      active: true,
      startDate: new Date().toISOString().split('T')[0],
      endDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0],
    },
  );

  const renderTab = () => {
    if (activeTab === 0) {
      return (
        <JobPostSettings
            setActiveTab={setActiveTab}
            jobTitleList={jobTitleList}
            jobPostingData={jobPostingData}
            setJobPostingData={setJobPostingData}
            jobDescription={jobDescription}
        />
      );
    } else if (activeTab === 1) {
      return (
        <JobPostDetails
            setActiveTab={setActiveTab}
            jobPostingData={jobPostingData}
            setJobPostingData={setJobPostingData}
            jobDescription={jobDescription}
        />
      );
    } else if (activeTab === 2) {
      return (
        <VideoMessageDetails
            setActiveTab={setActiveTab}
        />
      );
    } else {
      return (
        <Preview jobPostingData={jobPostingData}/>
      );
    }

  };

  return (
    <>
      <div>
        <Tabs
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            disableInactiveTabs={true}

        />
        { renderTab() }
      </div>
    </>
  );
};

export default JobPosting;
