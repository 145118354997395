import React, { useState } from 'react';
import { SecondaryTypography } from '../../../../../../ui/typography/typography';
import commonStyles
  from '../../../../../assessment/view/assessment_list/assessment_list_table/assessment_list_table.module.css';
import styles
  from '../../../../../assessment/view/view_assessment/assessment_result_list/assessment_result_list.module.css';

const Candidates = () => {

  return (
    <>
      <div>
        <table
            className={commonStyles.tableStyle}
        >
          <thead>
            <tr
                className={commonStyles.tableHeader}
            >
              <th>
                <div
                    className={styles.sortCol}
                >
                  <SecondaryTypography.XSmall
                      fontWeight='semi-bold'
                      textCase='uppercase'
                      alignment='center'
                  >
                    Select all
                  </SecondaryTypography.XSmall>

                </div>
              </th>
              <th>
                <div
                    className={styles.sortCol}
                >
                  <SecondaryTypography.XSmall
                      fontWeight='semi-bold'
                      textCase='uppercase'
                      alignment='center'
                  >
                    Candidate ID
                  </SecondaryTypography.XSmall>
                  <div className={styles.sortArrows}>

                  </div>
                </div>
              </th>
              <th>
                <div
                    className={styles.sortCol}
                >
                  <SecondaryTypography.XSmall
                      fontWeight='semi-bold'
                      textCase='uppercase'
                      alignment='center'
                  >
                    Name
                  </SecondaryTypography.XSmall>
                  <div className={styles.sortArrows}>

                  </div>
                </div>
              </th>

              <th>
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Email
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Applicant Status
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Invited
                </SecondaryTypography.XSmall>
              </th>
              <th>
                <SecondaryTypography.XSmall
                    fontWeight='semi-bold'
                    textCase='uppercase'
                    alignment='center'
                >
                  Assessment Status
                </SecondaryTypography.XSmall>
              </th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>
      </div>
    </>
  );
};

export default Candidates;
