import React, { useEffect, useState } from 'react';
import inputStyles from '../../../../../ui/input/input.module.css';
import { PaginationController } from '../../../../../ui/pagination_controller/pagination_controller';
import TableSkeletonLoader from '../../../../../ui/TableSkeletonLoader';
import { SecondaryTypography } from '../../../../../ui/typography/typography';
import { searchAssessmentList } from '../../../../../utils/api_service/assessment_api';
import { Assessment, AssessmentsSearchObject } from '../../../../../utils/types/assessment_types';
import styles from './assessment_list_table.module.css';
import { AssessmentRow } from './assessment_row/assessment_row';
import AssessmentFilter from './AssessmentFilter';

type AssessmentTableProps = {
    activeTab: number,
    searchObject: AssessmentsSearchObject,
    setSearchObject: (assessmentsSearchObject: AssessmentsSearchObject) => void,
}

export const AssessmentListTable = ({
  activeTab,
  searchObject,
  setSearchObject,
}: AssessmentTableProps) => {

  const [totalPages, setTotalPages] = useState<number>(1);
  const [paginatedAssessments, setPaginatedAssessments] = useState<Assessment[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const debounceSearchTerm = setTimeout(() => {
      searchAssessmentList(searchObject).then(async response => {
        setTotalPages(Math.ceil(response.totalAssessments/searchObject.limit));
        setPaginatedAssessments(response.assessments);
      }).finally(() => {setLoading(false);});}, 500);

    return () => clearTimeout(debounceSearchTerm);
  }, [searchObject]);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchObject({
      ...searchObject,
      assessmentName: event.target.value,
      page: 1,
    });
  };

  const renderTable = () => {
    if (loading) {
      return <TableSkeletonLoader numRows={11} height={10}/>;
    }
    if(paginatedAssessments !== undefined) {
      if (paginatedAssessments.length > 0) {
        return (
          <table
              className={styles.tableStyle}
          >
            <thead>
              <tr
                  className={styles.tableHeader}
              >
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Name of Assessment
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Assessment Type
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Creator
                  </SecondaryTypography.XSmall>
                </th>
                <th>
                  <SecondaryTypography.XSmall
                      alignment='center'
                      textCase='uppercase'
                      fontWeight='semi-bold'
                  >
                    Status
                  </SecondaryTypography.XSmall>
                </th>
              </tr>
            </thead>
            <tbody>
              { paginatedAssessments?.map((item: Assessment) => {
                return (
                  <AssessmentRow
                      key={item.id}
                      assessment={item}
                      assessmentList={paginatedAssessments}
                      setAssessments={setPaginatedAssessments}
                  />
                );
              }) }
            </tbody>
            { loading && <TableSkeletonLoader numRows={10} height={12}/> }
          </table>
        );
      } else {
        return (
          <div
              className={styles.assessmentListEmpty}
          >
            <SecondaryTypography.XLarge
                fontWeight='semi-bold'
            >
              No { activeTab === 0 ? 'Active' : 'In-Active' } Assessments Available
            </SecondaryTypography.XLarge>
          </div>
        );
      }
    }
  };

  return (
    <>
      <AssessmentFilter
          searchObject={searchObject}
          setSearchObject={setSearchObject}
      />
      <input
          className={inputStyles.inputStyles}
          type='text'
          placeholder='Search'
          value={searchObject.assessmentName}
          onChange={handleSearchTermChange}
      />
      { renderTable() }
      <PaginationController
          pageNumber={searchObject.page}
          maxPageNumber={totalPages}
          navigateNextPage={
            () => setSearchObject({
              ...searchObject,
              page: searchObject.page + 1,
            })
          }
          navigatePreviousPage={
            () => setSearchObject({
              ...searchObject,
              page: searchObject.page - 1,
            })
          }
      />
    </>
  );
};
