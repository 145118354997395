import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { getJobPost } from '../../../../utils/api_service/JobPosting';
import { JobPostingData } from '../../../../utils/types/JobOpenings';

const JobPostDetails = () => {
  const [template, setTemplate] = useState<string>('');
  const [jobPostData, setJobPostData] = useState<JobPostingData>();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchJobPost = async () => {
      try {
        const response = await getJobPost(id);
        const responseData = await response.json();
        setJobPostData(responseData);
        setTemplate(responseData.htmlTemplate);
      } catch (error) {
        console.error('Error fetching job post:', error);
      }
    };

    fetchJobPost(); // Call the async function
  }, []);

  const handleButtonClick = () => {
    console.log();
  };

  return (
    <div className='w-full bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100 p-8 rounded-lg shadow-2xl'>
      <div className='flex flex-col lg:flex-row gap-8'>
        { /* Left Column: Job Details */ }
        <div
            className='lg:w-7/12 w-full p-8 bg-white rounded-lg shadow-lg transform transition duration-300 hover:shadow-xl'>
          <div className='mb-8'>
            <SecondaryTypography.Large fontWeight='bold' className='text-4xl text-gray-900'>
              { jobPostData?.jobTitle }
            </SecondaryTypography.Large>
          </div>

          <div className='space-y-2 '>
            { [
              { label: 'Location', value: jobPostData?.location },
              { label: 'Department', value: jobPostData?.department },
              { label: 'Working method', value: jobPostData?.workType },
            ].map(({ label, value }) => (
              <div key={label} className='flex items-center py-2'>
                <div className='w-1/3 prose text-gray-700 text-sm'>
                  <p>{ label }:</p>
                </div>
                <div className='w-2/3 prose text-gray-700 text-sm'>
                  <p>{ value }</p>
                </div>
              </div>
            )) }
          </div>

          <div
              className='prose mt-8 text-gray-700 text-sm max-w-none'
              dangerouslySetInnerHTML={{ __html: template }}
          />
        </div>

        { /* Right Column: Actions and Media */ }
        <div
            className='w-full lg:w-96 p-6 bg-white rounded-xl shadow-lg space-y-8 transition-transform duration-300 transform'>
          { [
            { label: 'Apply Now', bgColor: 'blue-600' },
            { label: 'Email a Friend', bgColor: 'teal-600' },
          ].map(({ label, bgColor }) => (
            <div key={label} className='flex justify-center'>
              <button
                  onClick={handleButtonClick}
                  className={`w-full max-w-xs bg-${bgColor} text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-all duration-300 
                  hover:bg-opacity-90 active:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${bgColor} focus:ring-opacity-50`}>
                { label }
              </button>
            </div>
          )) }

          <div className='flex items-center justify-center bg-gradient-to-b from-gray-100 to-gray-50 rounded-lg p-4 shadow-md'>
            <div className='relative w-full h-48 rounded-lg overflow-hidden shadow-lg'>
              <video className='w-full h-full object-cover rounded-lg' autoPlay loop muted>
                <source src='path/to/your/video.mp4' type='video/mp4' />
                Your browser does not support the video tag.
              </video>
              <div className='absolute inset-0 bg-black bg-opacity-40 transition-opacity duration-300 hover:bg-opacity-20'></div>
              <div className='absolute bottom-0 left-0 right-0 p-4 text-white bg-gradient-to-t from-black to-transparent'>
                <h3 className='text-lg font-semibold'>Your Video Title</h3>
                <p className='text-sm'>A brief description or tagline goes here.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostDetails;
