import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import ConfirmationModal  from '../../../../ui/ConfirmationModal';
import { TrashIcon } from '../../../../ui/icons/icons';
import { SecondaryTypography } from '../../../../ui/typography/typography';
import { Permission } from '../../../../utils/helpers/permissions';
import PermissionGate from '../../../../utils/hooks/PermissionGate';
import { OrganizationUser } from '../../../../utils/types/admin_types';
import rowStyles from '../../access_management/row.module.css';

type CandidateRowProps = {
  candidate: OrganizationUser;
  deleteUser: (id: string) => void;
};

export const CandidateRow = ({
  candidate,
  deleteUser,
}: CandidateRowProps) => {
  const [showModal, setShowModal] = useState(false);

  const groupListString = useMemo(() => {
    return candidate.groups.map(group => group.groupName).join(', ');
  }, [candidate.groups]);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const confirmDelete = () => {
    deleteUser(candidate.id);
    setShowModal(false);
  };

  return (
    <>
      <tr className={rowStyles.tableRowStyle}>
        <td>
          <input type='checkbox' />
        </td>
        <td className={classNames(rowStyles.tableRowStyle, rowStyles.leftBorder)}>
          <SecondaryTypography.XSmall>
            { candidate.user.firstName + ' ' + candidate.user.lastName }
          </SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>{ candidate.user.email }</SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>{ groupListString || 'Empty' }</SecondaryTypography.XSmall>
        </td>
        <td>
          <SecondaryTypography.XSmall>
            { candidate.lastActive ? new Date(candidate.lastActive * 1000).toString() : 'Never Logged-In' }
          </SecondaryTypography.XSmall>
        </td>
        <td className={classNames(rowStyles.tableRowStyle, rowStyles.rightBorder)}>
          <div className={rowStyles.iconContainer}>
            <PermissionGate requiredPermissions={[Permission.CANDIDATE_DELETE]}>
              <div
                  className={classNames(rowStyles.icons, rowStyles.trashIcon, rowStyles.clickable)}
                  onClick={handleDeleteClick}
              >
                <TrashIcon />
              </div>
            </PermissionGate>
          </div>
        </td>
      </tr>
      { showModal && (
        <ConfirmationModal
            message='Are you sure you want to delete this candidate?'
            onConfirm={confirmDelete}
            onCancel={() => setShowModal(false)}
        />
      ) }
    </>
  );
};
