import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { CustomSelect } from '../../../../ui/custom_react_select/custom_select';
import { getJobFilters } from '../../../../utils/api_service/JobPosting';
import { getOrganizationTree } from '../../../../utils/api_service/organization_unit';
import { FilterState } from '../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../utils/types/react_select_types';

type FilterProps = {
    filters: FilterState;
    setFilters: React.Dispatch<React.SetStateAction<FilterState>>;
    setSearch: (value: boolean) => void,
    search: boolean,
}

const JobPostFilter = ({ filters, setFilters, setSearch, search }: FilterProps) => {

  const [departmentTypeFilter, setDepartmentTypeFilter] = useState<SelectOptions[]>([]);
  const [locations, setLocations] = useState<SelectOptions[]>([]);
  const [jobTitles, setJobTitles] = useState<SelectOptions[]>([]);

  const workTypes: SelectOptions[] = [{
    label: 'Hybrid',
    value: 'Hybrid',
  }, {
    label: 'Remote',
    value: 'Remote',
  }, {
    label: 'Office',
    value: 'Office',
  }];

  const sortType: SelectOptions[] = [{
    label: 'Relevant',
    value: 'Relevant',
  }, {
    label: 'Latest Jobs',
    value: 'Latest',
  }];

  useEffect(() => {
    const fetchJobFilters = async () => {
      const response = await getJobFilters();
      const responseData = await response.json();
      const formattedLocations = responseData.location.map((loc: string) => ({
        label: loc,
        value: loc,
      }));
      const formattedJobTitles = responseData.jobTitle.map((title: string) => ({
        label: title,
        value: title,
      }));

      setLocations(formattedLocations);
      setJobTitles(formattedJobTitles);
    };
    getOrganizationTree().then(response => {
      const departmentTypes = getAllUnitNames(response);
      setDepartmentTypeFilter(departmentTypes);
    });

    fetchJobFilters();
  }, []);

  const getAllUnitNames = (organization: any): SelectOptions[] => {
    const unitNames: SelectOptions[] = [];

    const traverse = (node: any) => {
      // Add the current unit's name to the list
      unitNames.push({
        label: node.unitName,
        value: node.unitName,
      });

      // Recursively traverse the children
      if (node.children && node.children.length > 0) {
        node.children.forEach((child: any) => {
          traverse(child);
        });
      }
    };

    // Start traversal from the root node
    traverse(organization);

    return unitNames;
  };

  const handleSelectChange = (action: any, actionValues: any) => {
    setFilters({
      ...filters,
      [actionValues.name === 'locationType' ? 'location' : 'jobTitle']: [action.value], // Wrap in an array
    });
  };

  const handleWorkType = (selectedOption: SelectOptions) => {
    const workType = selectedOption.value as string; // assuming `selectedOption.value` is a string
    setFilters(prevFilters => ({
      ...prevFilters,
      workType: [...prevFilters.workType, workType], // add to the array
    }));
  };

  const handleDepartment = (selectedOption: SelectOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      department: [...prevFilters.department, selectedOption.value], // Append the new department
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      jobTitle: [],
      location: [],
      workType: [],
      department: [],
    });
    setSearch(!search);
  };

  return (
    <div className='p-4'>
      { /* Search Bars */ }
      <div className='flex space-x-4 mb-4'>
        <div
            className='w-full'
        >
          <Select
              placeholder='Search by Job Title'
              name='jobTitleType'
              options={jobTitles}
              onChange={handleSelectChange}
              isClearable={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              isMulti={false}
          />
        </div>

        <div
            className='w-full'
        >
          <Select
              placeholder='Search by Location'
              name='locationType'
              options={locations}
              onChange={handleSelectChange}
              isClearable={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              isMulti={false}
          />
        </div>
      </div>

      { /* Dropdowns and Buttons */ }
      <div className='flex justify-between items-center'>
        { /* Dropdowns */ }
        <div className='flex space-x-4'>
          <div className='w-48'>
            <CustomSelect
                name='workType'
                value={{
                  label: 'Select Work Type',
                  value: 'Select Work Type',
                }}
                options={workTypes}
                onChange={handleWorkType}
            />
          </div>

          <div className='w-48'>
            <CustomSelect
                name='department'
                value={{
                  label: 'Select Department',
                  value: 'Select Department',
                }}
                options={departmentTypeFilter}
                onChange={handleDepartment}
            />
          </div>

          <div className='w-48'>
            <CustomSelect
                name='department'
                value={{
                  label: 'Sort by',
                  value: 'Sort by',
                }}
                options={sortType}
                onChange={handleWorkType}
            />
          </div>
        </div>

        { /* Buttons */ }
        <div className='flex space-x-4'>
          <button
              onClick={() => {setSearch(!search);}}
              className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
          >
            Search
          </button>
          <button
              onClick={handleClearFilters}
              className='bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300'
          >
            Clear Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPostFilter;
